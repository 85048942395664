import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getAdminList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ADMIN_LIST, {
      params: params
    });
    yield put({
      type: Actions.ADMIN_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ADMIN_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAdminList() {
  while (true) {
    const action = yield take(Actions.ADMIN_LIST.GET);
    yield fork(getAdminList, action.params);
  }
}

export function* postAdminAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADMIN_ADD, { ...params });
    yield put({
      type: Actions.ADMIN_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADMIN_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdminAdd() {
  while (true) {
    const action = yield take(Actions.ADMIN_ADD.POST);
    yield fork(postAdminAdd, action.params);
  }
}

export function* postAdminUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADMIN_UPDATE, { ...params });
    yield put({
      type: Actions.ADMIN_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADMIN_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdminUpdate() {
  while (true) {
    const action = yield take(Actions.ADMIN_UPDATE.POST);
    yield fork(postAdminUpdate, action.params);
  }
}

export function* postAdminDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADMIN_DELETE, { ...params });
    yield put({
      type: Actions.ADMIN_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADMIN_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdminDelete() {
  while (true) {
    const action = yield take(Actions.ADMIN_DELETE.POST);
    yield fork(postAdminDelete, action.params);
  }
}
