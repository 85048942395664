import Model from "./model";
import { Type } from "class-transformer";

export default class Area extends Model {
    public parent_id?: number;
    public level?: number;
    public name?: string;
    public short_name?: string;
    public area_code?: string;

    @Type(() => Area)
    public children?: Area[];
}