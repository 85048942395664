import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getVodPlayable(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.VOD_PLAYABLE, {
      params: params
    });
    yield put({
      type: Actions.VOD_PLAYABLE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.VOD_PLAYABLE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchVodPlayable() {
  while (true) {
    const action = yield take(Actions.VOD_PLAYABLE.GET);
    yield fork(getVodPlayable, action.params);
  }
}

export function* getVodSig(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.VOD_SIG, { params: params });
    yield put({
      type: Actions.VOD_SIG.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.VOD_SIG.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchVodSig() {
  while (true) {
    const action = yield take(Actions.VOD_SIG.GET);
    yield fork(getVodSig, action.params);
  }
}
