import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getBasisBasis(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.BASIS_BASIS, { params: params });
    yield put({
      type: Actions.BASIS_BASIS.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.BASIS_BASIS.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchBasisBasis() {
  while (true) {
    const action = yield take(Actions.BASIS_BASIS.GET);
    yield fork(getBasisBasis, action.params);
  }
}


export function* getBasisHuike(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.BASIS_HUIKE, { params: params });
    yield put({
      type: Actions.BASIS_HUIKE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.BASIS_HUIKE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchBasisHuike() {
  while (true) {
    const action = yield take(Actions.BASIS_HUIKE.GET);
    yield fork(getBasisHuike, action.params);
  }
}

export function* getBasisCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.BASIS_COURSE, { params: params });
    yield put({
      type: Actions.BASIS_COURSE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.BASIS_COURSE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchBasisCourse() {
  while (true) {
    const action = yield take(Actions.BASIS_COURSE.GET);
    yield fork(getBasisCourse, action.params);
  }
}


export function* getBasisUser(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.BASIS_USER, { params: params });
    yield put({
      type: Actions.BASIS_USER.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.BASIS_USER.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchBasisUser() {
  while (true) {
    const action = yield take(Actions.BASIS_USER.GET);
    yield fork(getBasisUser, action.params);
  }
}


export function* getBasisSchedule(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.BASIS_SCHEDULE, { params: params });
    yield put({
      type: Actions.BASIS_SCHEDULE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.BASIS_SCHEDULE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchBasisSchedule() {
  while (true) {
    const action = yield take(Actions.BASIS_SCHEDULE.GET);
    yield fork(getBasisSchedule, action.params);
  }
}


