/**
 * 此组件只负责捕获子组件的渲染性错误，
 * 即当触发事件(如：onClick)之类的出现的错误，或者网络方面的异常，都无法捕获，所以这些情况，应该结合try...catch 或主动的方式处理
 */
import * as React from "react";
// import * as Raven from "raven-js";

// TODO: 补上uid key time逻辑, 逻辑写到store里
class ErrorBoundary extends React.Component {
  public state: { [key: string]: any } = {
    error: false
  };

  public componentDidCatch(error: any, stack: any) {
    console.log(error, stack);
    const deviceId = localStorage.getItem("device_id");
    if (deviceId) {
      // Raven.captureException(error, {
      //     extra: {
      //         "deviceId": deviceId,
      //         "uid": "",
      //         "key": "",
      //         "time": ""
      //     }
      // });
    }
    this.setState({
      error: true
    });
  }

  public render() {
    if (this.state.error) {
      return <div>errored</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
