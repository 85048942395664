import * as React from "react";
import Loadable from "react-loadable";
import { Route, Redirect } from "react-router-dom";

import Page from "../components/Page";
import RouterConfig from "./config";
import { Pages } from "core/enums/pages";
import { Permissions } from "core/enums/permissions";

import Log from "core/log";
import Loading from "core/components/Loading";

import { Map, List } from "immutable";
import Main from "view/main";

class Routers {
  public loadableTimeout: number = 3000;
  // public pages : {
  //     [key : string]: RouterConfig
  // } = {};
  public pages: Map<string, RouterConfig> = Map();
  public pagesOrderedKeys: List<string> = List();

  constructor() {
    this.init();
  }

  public getRouterComponents() {
    const arr: any[] = [];

    this.pagesOrderedKeys.forEach(key => {
      if (key) {
        //console.log("gogogogo: ", key);
        const config = this.pages.get(key);
        if (config) {
          arr.push(this.getRouter(config));
        }
      }
    });
    // for (const key in this.pages) {
    //     if (this.pages.hasOwnProperty(key)) {
    //         const val = this.pages[key];
    //         arr.push(
    //             this.getRouter(val)
    //         );
    //     }
    // }
    arr.push(
      <Route
        exact={true}
        path="/no_permission"
        component={Loadable({
          loader: () => import(`../components/NoPermission`),
          loading: () => <Loading />,
          delay: this.loadableTimeout
        })}
        key="no_permission_router"
      />
    );
    arr.push(
      <Route
        component={Loadable({
          loader: () => import(`../components/NoMatched`),
          loading: () => <Loading />,
          delay: this.loadableTimeout
        })}
        key="no_matched_router"
      />
    );

    return arr;
  }

  private getRouter(val: RouterConfig) {
    const r: {
      [s: string]: any;
    } = {};
    const tempPaths = this.getPageFullPath(val);
    const paths: { [key: string]: any } = tempPaths ? tempPaths : {};
    let path = paths.path;
    r.component = val.component;
    if (val.async) {
      r.component = paths.path;
    }
    if (!val.exact && paths.componentPath) {
      r.component = paths.componentPath;
    }
    if (val.page && val.page.name === Pages.LOGIN) {
      path = "/";
    }
    r.key = `page_${path}`;
    r.exact = val.exact;
    r.path = path;
    // redirect to login page if not login
    if (val.mustLogin && localStorage.getItem("isLogin") !== "true") {
      r.render = (props: any) => {
        Log.verbose("redirect to login");
        return (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location
              }
            }}
          />
        );
      };
    }
    if (val.hasChildren) {
      const defaultPagePath = val.page
        ? this.getDefaultPagePath(val.page.name)
        : null;
      if (defaultPagePath) {
        r.render = (props: any) => {
          Log.verbose("redirect to " + defaultPagePath);
          return (
            <Redirect
              to={{
                pathname: `${path}/${defaultPagePath}`,
                state: {
                  from: props.location
                }
              }}
            />
          );
        };
      }
    }

    return (
      <Route
        exact={r.exact}
        path={r.path}
        component={
          !r.hasOwnProperty("render")
            ? typeof r.component === "string"
              ? Loadable({
                  loader: () => import(`../../view${r.component}`),
                  loading: () => <Loading />,
                  delay: this.loadableTimeout
                })
              : r.component
            : undefined
        }
        render={r.hasOwnProperty("render") ? r.render : undefined}
        key={r.key}
      />
    );
  }

  public getPageFullPath(rc: RouterConfig | String) {
    if (rc instanceof RouterConfig) {
      rc = rc as RouterConfig;
      if (rc.page) {
        const page: Page = rc.page;
        const exact = rc.exact;
        let exactCompPath = "";
        if (!exact) {
          let pc = rc.parentConfig;
          while (pc) {
            if (pc.exact) {
              exactCompPath += "/" + (pc.page ? pc.page.name : "");
            }
            pc = pc.parentConfig;
          }
        }

        const pathComp: string[] = [];
        pathComp.unshift(page.name);
        let p = rc.parentConfig;
        while (p) {
          pathComp.unshift(p.page ? p.page.name : "");
          p = p.parentConfig;
        }
        return {
          componentPath: exactCompPath,
          path: `/${pathComp.join("/")}`
        };
      }
    } else {
      const routerConfig = this.pages.get(rc as string);
      if (routerConfig) {
        return this.getPageFullPath(routerConfig);
      }
    }
    return undefined;
  }

  private getDefaultPagePath(pageName: string) {
    const c = this.pages.find(config => {
      if (
        config &&
        config.page &&
        config.page.defaultOfParent &&
        config.parentConfig &&
        config.parentConfig &&
        config.parentConfig.page &&
        config.parentConfig.page.name === pageName
      ) {
        return true;
      }
      return false;
    });
    return c && c.page ? c.page.name : null;
    // for (const key in this.pages) {
    //     if (this.pages.hasOwnProperty(key)) {
    //         const val = this.pages[key];
    //         if(val.page.defaultOfParent && val.parentConfig && val.parentConfig.page.name == pageName){
    //             return val.page.name;
    //         }
    //     }
    // }
  }

  private init() {
    // main
    const mainPage = new Page(Permissions.NONE, Pages.MAIN);
    const main = new RouterConfig(mainPage, "", true, false, true, Main);

    // dashboard
    const dashboardPage = new Page(Permissions.NONE, Pages.DASHBOARD);
    const dashboard = new RouterConfig(dashboardPage, "概览", true, true, true);
    dashboard.parentConfig = main;
    this.pages = this.pages.set(Pages.DASHBOARD, dashboard);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.DASHBOARD);

    // users
    const usersPage = new Page(Permissions.NONE, Pages.USERS);
    const users = new RouterConfig(usersPage, "用户管理", true, true);
    users.parentConfig = main;
    this.pages = this.pages.set(Pages.USERS, users);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.USERS);

    // detail
    const userDetailPage = new Page(Permissions.NONE, Pages.USER_DETAIL);
    const userDetail = new RouterConfig(userDetailPage, "用户详情", true, true);
    userDetail.parentConfig = users;
    this.pages = this.pages.set(Pages.USER_DETAIL, userDetail);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.USER_DETAIL);

    // reply_record
    const userReplyRecordPage = new Page(Permissions.NONE, Pages.REPLY_RECORD);
    const userReplyRecord = new RouterConfig(
      userReplyRecordPage,
      "回课记录",
      true,
      true
    );
    userReplyRecord.parentConfig = userDetail;
    this.pages = this.pages.set(Pages.REPLY_RECORD, userReplyRecord);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.REPLY_RECORD);

    // subject_record
    const userSubjectRecordPage = new Page(
      Permissions.NONE,
      Pages.SUBJECT_RECORD
    );
    const userSubjectRecord = new RouterConfig(
      userSubjectRecordPage,
      "做题记录",
      true,
      true
    );
    userSubjectRecord.parentConfig = userDetail;
    this.pages = this.pages.set(Pages.SUBJECT_RECORD, userSubjectRecord);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.SUBJECT_RECORD);

    // create
    const userCreatePage = new Page(Permissions.NONE, Pages.USER_CREATE);
    const userCreate = new RouterConfig(userCreatePage, "创建用户", true, true);
    userCreate.parentConfig = users;
    this.pages = this.pages.set(Pages.USER_CREATE, userCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.USER_CREATE);

    // practise
    const practisePage = new Page(Permissions.NONE, Pages.ALL_PRACTISES);
    const practise = new RouterConfig(practisePage, "全部练习题", true, true);
    practise.parentConfig = main;
    this.pages = this.pages.set(Pages.ALL_PRACTISES, practise);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ALL_PRACTISES);

    // practise create
    const practiseCreatePage = new Page(
      Permissions.NONE,
      Pages.ALL_PRACTISES_CREATE
    );
    const practiseCreate = new RouterConfig(
      practiseCreatePage,
      "添加练习题",
      true,
      true
    );
    practiseCreate.parentConfig = practise;
    this.pages = this.pages.set(Pages.ALL_PRACTISES_CREATE, practiseCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.ALL_PRACTISES_CREATE
    );

    // practise detail
    // const practiseDetailPage = new Page(
    //   Permissions.NONE,
    //   Pages.ALL_PRACTISES_DATEIL
    // );
    // const practiseDetail = new RouterConfig(
    //   practiseDetailPage,
    //   "练习题详情",
    //   true,
    //   true
    // );
    // practiseDetail.parentConfig = practise;
    // this.pages = this.pages.set(Pages.ALL_PRACTISES_DATEIL, practiseDetail);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(
    //   Pages.ALL_PRACTISES_DATEIL
    // );

    // classes  班级管理
    const CLASSESPage = new Page(Permissions.NONE, Pages.CLASSES);
    const classes = new RouterConfig(CLASSESPage, "班级管理", true, true);
    classes.parentConfig = main;
    this.pages = this.pages.set(Pages.CLASSES, classes);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.CLASSES);
    // course chapter
    const attendLogsPage = new Page(Permissions.NONE, Pages.ATTEND_LOGS);
    const attendLogs = new RouterConfig(attendLogsPage, "上课日志", true, true);
    attendLogs.parentConfig = classes;
    this.pages = this.pages.set(Pages.ATTEND_LOGS, attendLogs);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ATTEND_LOGS);
    // lc_chapters 大班课章节
    const lcChapterPage = new Page(Permissions.NONE, Pages.LC_CHAPTER);
    const lcChapter = new RouterConfig(lcChapterPage, "班级管理", true, true);
    lcChapter.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_CHAPTER, lcChapter);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_CHAPTER);
    // large_classes 大班课课程
    const largeClassPage = new Page(Permissions.NONE, Pages.LARGE_CLASSES);
    const largeClass = new RouterConfig(largeClassPage, "课程管理", true, true);
    largeClass.parentConfig = main;
    this.pages = this.pages.set(Pages.LARGE_CLASSES, largeClass);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LARGE_CLASSES);
    // lcCourse save --大班课课程管理
    const lcCourseSavePage = new Page(
      Permissions.NONE,
      Pages.LARGE_CLASSES_SAVE
    );
    const lcCourseSave = new RouterConfig(
      lcCourseSavePage,
      "添加大班课课程",
      true,
      true
    );
    lcCourseSave.parentConfig = largeClass;
    this.pages = this.pages.set(Pages.LARGE_CLASSES_SAVE, lcCourseSave);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.LARGE_CLASSES_SAVE
    );
    // lc_subjects 大班课题库
    const lcSubjectPage = new Page(Permissions.NONE, Pages.LC_SUBJECT);
    const lcSubject = new RouterConfig(lcSubjectPage, "课程管理", true, true);
    lcSubject.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_SUBJECT, lcSubject);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_SUBJECT);

    // lcSubject create
    const lcSubjectCreatePage = new Page(
      Permissions.NONE,
      Pages.ALL_LC_SUBJECT_CREATE
    );
    const lcSubjectCreate = new RouterConfig(
      lcSubjectCreatePage,
      "添加练习题",
      true,
      true
    );
    lcSubjectCreate.parentConfig = lcSubject;
    this.pages = this.pages.set(Pages.ALL_LC_SUBJECT_CREATE, lcSubjectCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.ALL_LC_SUBJECT_CREATE
    );
    // knowledges lc_schedules 大班课排课
    const lcSchedulesPage = new Page(Permissions.NONE, Pages.LC_SCHEDULES);
    const lcSchedules = new RouterConfig(
      lcSchedulesPage,
      "大班课排课",
      true,
      true
    );
    lcSchedules.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_SCHEDULES, lcSchedules);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_SCHEDULES);

    //游戏环节
    const lcGameawardPage = new Page(Permissions.NONE, Pages.LC_GAMEAWARD);
    const lcGameaward = new RouterConfig(
      lcGameawardPage,
      "游戏奖品",
      true,
      true
    );
    lcGameaward.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_GAMEAWARD, lcGameaward);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_GAMEAWARD);
    //添加游戏奖品
    const lcGameawardSavePage = new Page(
      Permissions.NONE,
      Pages.LC_GAMEAWARD_SAVE
    );
    const lcGameawardSave = new RouterConfig(
      lcGameawardSavePage,
      "添加游戏奖品",
      true,
      true
    );
    lcGameawardSave.parentConfig = lcGameaward;
    this.pages = this.pages.set(Pages.LC_GAMEAWARD_SAVE, lcGameawardSave);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_GAMEAWARD_SAVE);

    //大宝箱
    const lcBoxawardPage = new Page(Permissions.NONE, Pages.LC_BOXAWARD);
    const lcBoxaward = new RouterConfig(
      lcBoxawardPage,
      "大宝箱奖品",
      true,
      true
    );
    lcBoxaward.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_BOXAWARD, lcBoxaward);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_BOXAWARD);
    //添加大宝箱奖品
    const lcBoxawardSavePage = new Page(
      Permissions.NONE,
      Pages.LC_BOXAWARD_SAVE
    );
    const lcBoxawardSave = new RouterConfig(
      lcBoxawardSavePage,
      "添加大宝箱奖品",
      true,
      true
    );
    lcBoxawardSave.parentConfig = lcBoxaward;
    this.pages = this.pages.set(Pages.LC_BOXAWARD_SAVE, lcBoxawardSave);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_BOXAWARD_SAVE);

    // knowledges lc_attend_logs 上课日志
    const lcAttendLogsPage = new Page(Permissions.NONE, Pages.LC_ATTEND_LOGS);
    const lcAttendLogs = new RouterConfig(
      lcAttendLogsPage,
      "上课日志",
      true,
      true
    );
    lcAttendLogs.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_ATTEND_LOGS, lcAttendLogs);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_ATTEND_LOGS);

    // knowledges 知识点管理
    const knowledgePage = new Page(Permissions.NONE, Pages.KNOWLEDGE);
    const knowledge = new RouterConfig(knowledgePage, "知识点管理", true, true);
    knowledge.parentConfig = main;
    this.pages = this.pages.set(Pages.KNOWLEDGE, knowledge);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.KNOWLEDGE);
    const knowledgeSavePage = new Page(Permissions.NONE, Pages.KNOWLEDGE_SAVE);
    //添加知识点
    const knowledgeSave = new RouterConfig(
      knowledgeSavePage,
      "添加知识点",
      true,
      true
    );
    knowledgeSave.parentConfig = knowledge;
    this.pages = this.pages.set(Pages.KNOWLEDGE_SAVE, knowledgeSave);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.KNOWLEDGE_SAVE);

    // knowledges kl_ranks 知识点对应阶段
    const KLRankPage = new Page(Permissions.NONE, Pages.KL_RANK);
    const KLRank = new RouterConfig(KLRankPage, "知识点阶位", true, true);
    KLRank.parentConfig = main;
    this.pages = this.pages.set(Pages.KL_RANK, KLRank);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.KL_RANK);

    // knowledges kl_types 知识点对应类型
    const KLTypePage = new Page(Permissions.NONE, Pages.KL_TYPE);
    const KLType = new RouterConfig(KLTypePage, "知识点类型", true, true);
    KLType.parentConfig = main;
    this.pages = this.pages.set(Pages.KL_TYPE, KLType);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.KL_TYPE);

    // knowledges lc_modules 知识点对应模块
    const LCMODULESPage = new Page(Permissions.NONE, Pages.LC_MODULES);
    const lcModules = new RouterConfig(LCMODULESPage, "大班课模块", true, true);
    lcModules.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_MODULES, lcModules);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_MODULES);

    // 大班课-音乐背景
    const LcBgsoundPage = new Page(Permissions.NONE, Pages.LC_BGSOUND);
    const lcBgsound = new RouterConfig(
      LcBgsoundPage,
      "大班课音乐背景",
      true,
      true
    );
    lcBgsound.parentConfig = main;
    this.pages = this.pages.set(Pages.LC_BGSOUND, lcBgsound);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LC_BGSOUND);

    // practise tags
    const practiseTagsPage = new Page(Permissions.NONE, Pages.PRACTISE_TAGS);
    const practiseTags = new RouterConfig(
      practiseTagsPage,
      "标签管理",
      true,
      true
    );
    practiseTags.parentConfig = main;
    this.pages = this.pages.set(Pages.PRACTISE_TAGS, practiseTags);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.PRACTISE_TAGS);

    // practise tags  class
    const TagClassPage = new Page(Permissions.NONE, Pages.TAGS_CLASS);
    const TagClass = new RouterConfig(TagClassPage, "标签分类", true, true);
    TagClass.parentConfig = main;
    this.pages = this.pages.set(Pages.TAGS_CLASS, TagClass);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.TAGS_CLASS);

    // practise tags  problem
    const TagProblemPage = new Page(Permissions.NONE, Pages.TAGS_PROBLEM);
    const TagProblem = new RouterConfig(
      TagProblemPage,
      "标签课程问题",
      true,
      true
    );
    TagProblem.parentConfig = main;
    this.pages = this.pages.set(Pages.TAGS_PROBLEM, TagProblem);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.TAGS_PROBLEM);

    // practise tags  grade
    const TagGradePage = new Page(Permissions.NONE, Pages.TAGS_GRADE);
    const TagGrade = new RouterConfig(TagGradePage, "标签等级", true, true);
    TagGrade.parentConfig = main;
    this.pages = this.pages.set(Pages.TAGS_GRADE, TagGrade);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.TAGS_GRADE);

    // practise difficulty
    const practiseDifficultyPage = new Page(
      Permissions.NONE,
      Pages.PRACTISE_DIFFICULTIES
    );
    const practiseDifficulty = new RouterConfig(
      practiseDifficultyPage,
      "难度管理",
      true,
      true
    );
    practiseDifficulty.parentConfig = main;
    this.pages = this.pages.set(
      Pages.PRACTISE_DIFFICULTIES,
      practiseDifficulty
    );
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.PRACTISE_DIFFICULTIES
    );

    // practise category
    const practiseCategoriePage = new Page(
      Permissions.NONE,
      Pages.PRACTISE_CATEGORIES
    );
    const practiseCategorie = new RouterConfig(
      practiseCategoriePage,
      "分类管理",
      true,
      true
    );
    practiseCategorie.parentConfig = main;
    this.pages = this.pages.set(Pages.PRACTISE_CATEGORIES, practiseCategorie);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.PRACTISE_CATEGORIES
    );

    // melody
    const allMelodyPage = new Page(Permissions.NONE, Pages.ALL_MELODIES);
    const allMelody = new RouterConfig(allMelodyPage, "曲谱管理", true, true);
    allMelody.parentConfig = main;
    this.pages = this.pages.set(Pages.ALL_MELODIES, allMelody);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ALL_MELODIES);

    // melody create
    const allMelodyCreatePage = new Page(
      Permissions.NONE,
      Pages.ALL_MELODIES_CREATE
    );
    const allMelodyCreate = new RouterConfig(
      allMelodyCreatePage,
      "添加曲谱",
      true,
      true
    );
    allMelodyCreate.parentConfig = allMelody;
    this.pages = this.pages.set(Pages.ALL_MELODIES_CREATE, allMelodyCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.ALL_MELODIES_CREATE
    );

    // melody detail
    const allMelodyDetailPage = new Page(
      Permissions.NONE,
      Pages.ALL_MELODIES_EDIT
    );
    const allMelodyDetail = new RouterConfig(
      allMelodyDetailPage,
      "曲谱详情",
      true,
      true
    );
    allMelodyDetail.parentConfig = allMelody;
    this.pages = this.pages.set(Pages.ALL_MELODIES_EDIT, allMelodyDetail);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ALL_MELODIES_EDIT);

    // melody guide
    const melodyGuidePage = new Page(Permissions.NONE, Pages.MELODY_GUIDES);
    const melodyGuides = new RouterConfig(
      melodyGuidePage,
      "曲谱引导库",
      true,
      true
    );
    melodyGuides.parentConfig = main;
    this.pages = this.pages.set(Pages.MELODY_GUIDES, melodyGuides);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.MELODY_GUIDES);

    // melody guide create
    const melodyGuideCreatePage = new Page(
      Permissions.NONE,
      Pages.MELODY_GUIDES_CREATE
    );
    const melodyGuideCreate = new RouterConfig(
      melodyGuideCreatePage,
      "添加引导",
      true,
      true
    );
    melodyGuideCreate.parentConfig = melodyGuides;
    this.pages = this.pages.set(Pages.MELODY_GUIDES_CREATE, melodyGuideCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.MELODY_GUIDES_CREATE
    );

    // melody guide detail
    const melodyGuideDetailPage = new Page(
      Permissions.NONE,
      Pages.MELODY_GUIDES_DETAIL
    );
    const melodyGuideDetail = new RouterConfig(
      melodyGuideDetailPage,
      "引导详情",
      true,
      true
    );
    melodyGuideDetail.parentConfig = melodyGuides;
    this.pages = this.pages.set(Pages.MELODY_GUIDES_DETAIL, melodyGuideDetail);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(
      Pages.MELODY_GUIDES_DETAIL
    );

    // guide category
    const guideCategoryPage = new Page(Permissions.NONE, Pages.GUIDE_CATEGORY);
    const guideCategorys = new RouterConfig(
      guideCategoryPage,
      "引导分类",
      true,
      true
    );
    guideCategorys.parentConfig = main;
    this.pages = this.pages.set(Pages.GUIDE_CATEGORY, guideCategorys);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.GUIDE_CATEGORY);

    // course material
    const courseMaterialPage = new Page(
      Permissions.NONE,
      Pages.COURSE_MATERIAL
    );
    const courseMaterial = new RouterConfig(
      courseMaterialPage,
      "课程教材",
      true,
      true
    );
    courseMaterial.parentConfig = main;
    this.pages = this.pages.set(Pages.COURSE_MATERIAL, courseMaterial);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.COURSE_MATERIAL);

    // course chapter
    const courseChapterPage = new Page(Permissions.NONE, Pages.COURSE_CHAPTER);
    const courseChapter = new RouterConfig(
      courseChapterPage,
      "课程章节",
      true,
      true
    );
    courseChapter.parentConfig = courseMaterial;
    this.pages = this.pages.set(Pages.COURSE_CHAPTER, courseChapter);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.COURSE_CHAPTER);

    // course lesson
    const courseLessonPage = new Page(Permissions.NONE, Pages.COURSE_LESSON);
    const courseLesson = new RouterConfig(courseLessonPage, "课程", true, true);
    courseLesson.parentConfig = courseMaterial;
    this.pages = this.pages.set(Pages.COURSE_LESSON, courseLesson);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.COURSE_LESSON);

    // schedules
    const schedulePage = new Page(Permissions.NONE, Pages.SCHEDULES);
    const schedule = new RouterConfig(schedulePage, "排课管理", true, true);
    schedule.parentConfig = main;
    this.pages = this.pages.set(Pages.SCHEDULES, schedule);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.SCHEDULES);

    // course
    const coursePage = new Page(Permissions.NONE, Pages.COURSES);
    const courses = new RouterConfig(coursePage, "课程管理", true, true);
    courses.parentConfig = main;
    this.pages = this.pages.set(Pages.COURSES, courses);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.COURSES);

    // activity
    const actPage = new Page(Permissions.NONE, Pages.ACTIVITIES);
    const acts = new RouterConfig(actPage, "活动管理", true, true);
    acts.parentConfig = main;
    this.pages = this.pages.set(Pages.ACTIVITIES, acts);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ACTIVITIES);

    // activity add
    // const actAddPage = new Page(Permissions.NONE, Pages.ACTIVITIESADD);
    // const actAdd = new RouterConfig(actAddPage, "活动管理", true, true);
    // actAdd.parentConfig = acts;
    // this.pages = this.pages.set(Pages.ACTIVITIESADD, actAdd);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ACTIVITIESADD);

    // activity update
    const actUpdatePage = new Page(Permissions.NONE, Pages.ACTIVITIESUPDATE);
    const actUpdat = new RouterConfig(actUpdatePage, "活动管理", true, true);
    actUpdat.parentConfig = acts;
    this.pages = this.pages.set(Pages.ACTIVITIESUPDATE, actUpdat);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ACTIVITIESUPDATE);

    // activity edit
    // const actEditPage = new Page(Permissions.NONE, Pages.ACTIVITIESEDIT);
    // const actEdit = new RouterConfig(actEditPage, "活动详情", true, true);
    // actEdit.parentConfig = acts;
    // this.pages = this.pages.set(Pages.ACTIVITIESEDIT, actEdit);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ACTIVITIESEDIT);

    // ads
    const adsPage = new Page(Permissions.NONE, Pages.ADS);
    const ads = new RouterConfig(adsPage, "广告管理", true, true);
    ads.parentConfig = main;
    this.pages = this.pages.set(Pages.ADS, ads);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADS);

    // ads create
    // const adsCreatePage = new Page(Permissions.NONE, Pages.ADS_CREATE);
    // const adsCreate = new RouterConfig(adsCreatePage, "广告添加", true, true);
    // adsCreate.parentConfig = ads;
    // this.pages = this.pages.set(Pages.ADS_CREATE, adsCreate);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADS_CREATE);

    // ads edit
    // const adsEditPage = new Page(Permissions.NONE, Pages.ADS_DETAIL);
    // const adsEdit = new RouterConfig(adsEditPage, "广告详情", true, true);
    // adsEdit.parentConfig = ads;
    // this.pages = this.pages.set(Pages.ADS_DETAIL, adsEdit);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADS_DETAIL);

    // ads update
    const adsUpdatePage = new Page(Permissions.NONE, Pages.ADS_UPDATE);
    const adsUpdate = new RouterConfig(adsUpdatePage, "广告修改", true, true);
    adsUpdate.parentConfig = ads;
    this.pages = this.pages.set(Pages.ADS_UPDATE, adsUpdate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADS_UPDATE);

    // pack
    const packPage = new Page(Permissions.NONE, Pages.PACK);
    const pack = new RouterConfig(packPage, "课包管理", true, true);
    pack.parentConfig = main;
    this.pages = this.pages.set(Pages.PACK, pack);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.PACK);

    // pack module
    const packModulePage = new Page(Permissions.NONE, Pages.PACKMODULE);
    const packModule = new RouterConfig(packModulePage, "课包管理", true, true);
    packModule.parentConfig = pack;
    this.pages = this.pages.set(Pages.PACKMODULE, packModule);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.PACKMODULE);

    // ai
    const aiPage = new Page(Permissions.NONE, Pages.AI);
    const ai = new RouterConfig(aiPage, "AI课程管理", true, true);
    ai.parentConfig = main;
    this.pages = this.pages.set(Pages.AI, ai);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AI);

    // ai edit
    const aiEditPage = new Page(Permissions.NONE, Pages.AI_DETAIL);
    const aiEdit = new RouterConfig(aiEditPage, "AI详情", true, true);
    aiEdit.parentConfig = ai;
    this.pages = this.pages.set(Pages.AI_DETAIL, aiEdit);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AI_DETAIL);

    //创建AI题列表
    const aiClassAddPage = new Page(Permissions.NONE, Pages.AI_CLASS_ADD);
    const aiClassAdd = new RouterConfig(aiClassAddPage, "AI详情", true, true);
    aiClassAdd.parentConfig = aiEdit;
    this.pages = this.pages.set(Pages.AI_CLASS_ADD, aiClassAdd);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AI_CLASS_ADD);
    // ai课程题库

    // articles
    const articlesPage = new Page(Permissions.NONE, Pages.ARTICLES);
    const articles = new RouterConfig(articlesPage, "文章管理", true, true);
    articles.parentConfig = main;
    this.pages = this.pages.set(Pages.ARTICLES, articles);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ARTICLES);

    const articles_classPage = new Page(Permissions.NONE, Pages.ARTICLES_CLASS);
    const articles_class = new RouterConfig(
      articles_classPage,
      "文章分类",
      true,
      true
    );
    articles_class.parentConfig = main;
    this.pages = this.pages.set(Pages.ARTICLES_CLASS, articles_class);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ARTICLES_CLASS);

    // // 文章详情 article  edit
    // const articleEditPage = new Page(Permissions.NONE, Pages.ARTICLE_EDITS);
    // const articleEdit = new RouterConfig(
    //   articleEditPage,
    //   "article详情",
    //   true,
    //   true
    // );
    // articleEdit.parentConfig = articles;
    // this.pages = this.pages.set(Pages.ARTICLE_EDITS, articleEdit);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ARTICLE_EDITS);

    // // 文章详情 article  update
    // const articleUpdatePage = new Page(Permissions.NONE, Pages.ARTICLES_UPDATE);
    // const articleUpdate = new RouterConfig(
    //   articleUpdatePage,
    //   "article修改",
    //   true,
    //   true
    // );
    // articleUpdate.parentConfig = articles;
    // this.pages = this.pages.set(Pages.ARTICLES_UPDATE, articleUpdate);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ARTICLES_UPDATE);

    // // 文章详情 article  add
    const articleAddPage = new Page(Permissions.NONE, Pages.ARTICLES_ADD);
    const articleAdd = new RouterConfig(
      articleAddPage,
      "articleADD",
      true,
      true
    );
    articleAdd.parentConfig = articles;
    this.pages = this.pages.set(Pages.ARTICLES_ADD, articleAdd);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ARTICLES_ADD);

    // faq
    const faqPage = new Page(Permissions.NONE, Pages.FAQS);
    const faq = new RouterConfig(faqPage, "FAQ管理", true, true);
    faq.parentConfig = main;
    this.pages = this.pages.set(Pages.FAQS, faq);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.FAQS);

    // faq create
    // const faqCreatePage = new Page(Permissions.NONE, Pages.FAQ_CREATE);
    // const faqCreate = new RouterConfig(faqCreatePage, "FAQ添加", true, true);
    // faqCreate.parentConfig = faq;
    // this.pages = this.pages.set(Pages.FAQ_CREATE, faqCreate);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.FAQ_CREATE);

    // faq edit
    const faqEditPage = new Page(Permissions.NONE, Pages.FAQ_EDIT);
    const faqEdit = new RouterConfig(faqEditPage, "FAQ详情", true, true);
    faqEdit.parentConfig = faq;
    this.pages = this.pages.set(Pages.FAQ_EDIT, faqEdit);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.FAQ_EDIT);

    // advise
    const advisePage = new Page(Permissions.NONE, Pages.ADVISES);
    const advise = new RouterConfig(advisePage, "意见建议", true, true);
    advise.parentConfig = main;
    this.pages = this.pages.set(Pages.ADVISES, advise);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADVISES);

    // advise detail
    const adviseDetailPage = new Page(Permissions.NONE, Pages.ADVISES_DETAIL);
    const dviseDetail = new RouterConfig(
      adviseDetailPage,
      "意见建议详情",
      true,
      true
    );
    dviseDetail.parentConfig = advise;
    this.pages = this.pages.set(Pages.ADVISES_DETAIL, dviseDetail);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADVISES_DETAIL);

    // solutions
    const solutionsPage = new Page(Permissions.NONE, Pages.SOLUTIONS);
    const solutions = new RouterConfig(solutionsPage, "解决方案", true, true);
    solutions.parentConfig = main;
    this.pages = this.pages.set(Pages.SOLUTIONS, solutions);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.SOLUTIONS);

    // solution_create
    // const solutionCreatePage = new Page(
    //   Permissions.NONE,
    //   Pages.SOLUTION_CREATE
    // );
    // const solutionCreate = new RouterConfig(
    //   solutionCreatePage,
    //   "创建方案",
    //   true,
    //   true
    // );
    // solutionCreate.parentConfig = solutions;
    // this.pages = this.pages.set(Pages.SOLUTION_CREATE, solutionCreate);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.SOLUTION_CREATE);

    // solution_edit
    // const solutionEditPage = new Page(Permissions.NONE, Pages.SOLUTION_EDIT);
    // const solutionEdit = new RouterConfig(
    //   solutionEditPage,
    //   "修改方案",
    //   true,
    //   true
    // );
    // solutionEdit.parentConfig = solutions;
    // this.pages = this.pages.set(Pages.SOLUTION_EDIT, solutionEdit);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.SOLUTION_EDIT);

    // solution_update
    const solutionUpdatePage = new Page(
      Permissions.NONE,
      Pages.SOLUTIONS_UPDATE
    );
    const solutionUpdate = new RouterConfig(
      solutionUpdatePage,
      "音频库",
      true,
      true
    );
    solutionUpdate.parentConfig = solutions;
    this.pages = this.pages.set(Pages.SOLUTIONS_UPDATE, solutionUpdate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.SOLUTIONS_UPDATE);

    // notification
    const notificationPage = new Page(Permissions.NONE, Pages.NOTIFICATIONS);
    const notifications = new RouterConfig(
      notificationPage,
      "通知管理",
      true,
      true
    );
    notifications.parentConfig = main;
    this.pages = this.pages.set(Pages.NOTIFICATIONS, notifications);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.NOTIFICATIONS);

    //notification 修改
    // const notifiUpdatePage = new Page(Permissions.NONE, Pages.NOTIFIUPDATE);
    // const notifiUpdate = new RouterConfig(notifiUpdatePage, "通知", true, true);
    // notifiUpdate.parentConfig = notifications;
    // this.pages = this.pages.set(Pages.NOTIFIUPDATE, notifiUpdate);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.NOTIFIUPDATE);

    //notification ADD
    const notifiAddPage = new Page(Permissions.NONE, Pages.NOTIFIADD);
    const notifiAdd = new RouterConfig(notifiAddPage, "通知ADD", true, true);
    notifiAdd.parentConfig = notifications;
    this.pages = this.pages.set(Pages.NOTIFIADD, notifiAdd);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.NOTIFIADD);

    //notification edit
    // const notifiEditPage = new Page(Permissions.NONE, Pages.NOTIFI_EDIT);
    // const notifiEdit = new RouterConfig(notifiEditPage, "通知edit", true, true);
    // notifiEdit.parentConfig = notifications;
    // this.pages = this.pages.set(Pages.NOTIFI_EDIT, notifiEdit);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.NOTIFI_EDIT);

    // operating 操作管理
    const operatingPage = new Page(Permissions.NONE, Pages.OPERATING);
    const operating = new RouterConfig(operatingPage, "操作管理", true, true);
    operating.parentConfig = main;
    this.pages = this.pages.set(Pages.OPERATING, operating);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.OPERATING);

    // videos
    const videosPage = new Page(Permissions.NONE, Pages.VIDEOS);
    const videos = new RouterConfig(videosPage, "视频库", true, true, true);
    videos.parentConfig = main;
    this.pages = this.pages.set(Pages.VIDEOS, videos);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.VIDEOS);

    // videos edit
    // const videosDetailPage = new Page(Permissions.NONE, Pages.VIDEOS_DETAIL);
    // const videosDetail = new RouterConfig(
    //   videosDetailPage,
    //   "视频库",
    //   true,
    //   true,
    //   true
    // );
    // videosDetail.parentConfig = videos;
    // this.pages = this.pages.set(Pages.VIDEOS_DETAIL, videosDetail);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.VIDEOS_DETAIL);

    // audios
    const audiosPage = new Page(Permissions.NONE, Pages.AUDIOS);
    const audios = new RouterConfig(audiosPage, "曲谱库", true, true, true);
    audios.parentConfig = main;
    this.pages = this.pages.set(Pages.AUDIOS, audios);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AUDIOS);

    // above_class
    const above_classPage = new Page(Permissions.NONE, Pages.ABOVE_CLASS);
    const above_class = new RouterConfig(
      above_classPage,
      "卡片",
      true,
      true,
      true
    );
    above_class.parentConfig = main;
    this.pages = this.pages.set(Pages.ABOVE_CLASS, above_class);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ABOVE_CLASS);

    // configuration
    const configurationsPage = new Page(Permissions.NONE, Pages.CONFIGURATIONS);
    const configurations = new RouterConfig(
      configurationsPage,
      "全部配置",
      true,
      true
    );
    configurations.parentConfig = main;
    this.pages = this.pages.set(Pages.CONFIGURATIONS, configurations);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.CONFIGURATIONS);

    // agreements
    const agreementsPage = new Page(Permissions.NONE, Pages.AGREEMENTS);
    const agreements = new RouterConfig(agreementsPage, "平台协议", true, true);
    agreements.parentConfig = main;
    this.pages = this.pages.set(Pages.AGREEMENTS, agreements);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AGREEMENTS);

    // agreements details
    // const agreementsDetailPage = new Page(
    //   Permissions.NONE,
    //   Pages.AGREEMENTSDETAIL
    // );
    // const agreementsDetail = new RouterConfig(
    //   agreementsDetailPage,
    //   "平台协议",
    //   true,
    //   true
    // );
    // agreementsDetail.parentConfig = agreements;
    // this.pages = this.pages.set(Pages.AGREEMENTSDETAIL, agreementsDetail);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AGREEMENTSDETAIL);

    // agreements add
    // const agreementsAddPage = new Page(Permissions.NONE, Pages.AGREEMENTSADD);
    // const agreementsAdd = new RouterConfig(
    //   agreementsAddPage,
    //   "平台协议",
    //   true,
    //   true
    // );
    // agreementsAdd.parentConfig = agreements;
    // this.pages = this.pages.set(Pages.AGREEMENTSADD, agreementsAdd);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AGREEMENTSADD);

    // agreements copy
    const agreementsCopyPage = new Page(
      Permissions.NONE,
      Pages.AGREEMENTS_COPY
    );
    const agreementsCopy = new RouterConfig(
      agreementsCopyPage,
      "平台协议",
      true,
      true
    );
    agreementsCopy.parentConfig = agreements;
    this.pages = this.pages.set(Pages.AGREEMENTS_COPY, agreementsCopy);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AGREEMENTS_COPY);

    // admins
    const adminsPage = new Page(Permissions.NONE, Pages.ADMINS);
    const admins = new RouterConfig(adminsPage, "全部管理员", true, true);
    admins.parentConfig = main;
    this.pages = this.pages.set(Pages.ADMINS, admins);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ADMINS);

    // logs
    const logPage = new Page(Permissions.NONE, Pages.LOGS);
    const logs = new RouterConfig(logPage, "日志管理", true, true);
    logs.parentConfig = main;
    this.pages = this.pages.set(Pages.LOGS, logs);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LOGS);

    // orgs
    const orgPage = new Page(Permissions.NONE, Pages.ORGS);
    const orgs = new RouterConfig(orgPage, "机构管理", true, true);
    orgs.parentConfig = main;
    this.pages = this.pages.set(Pages.ORGS, orgs);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ORGS);

    // org detail
    // const orgDetailPage = new Page(Permissions.NONE, Pages.ORG_DETAIL);
    // const orgDetail = new RouterConfig(orgDetailPage, "机构详情", true, true);
    // orgDetail.parentConfig = orgs;
    // this.pages = this.pages.set(Pages.ORG_DETAIL, orgDetail);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ORG_DETAIL);

    // org 机构详情
    const orgEditPage = new Page(Permissions.NONE, Pages.ORG_EDIT);
    const orgEdit = new RouterConfig(orgEditPage, "机构详情", true, true);
    orgEdit.parentConfig = orgs;
    this.pages = this.pages.set(Pages.ORG_EDIT, orgEdit);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ORG_EDIT);

    // const orgDetailsPage = new Page(Permissions.NONE, Pages.ORG_DETAILS);
    // const orgDetails = new RouterConfig(
    //   orgDetailsPage,
    //   "机构详情s",
    //   true,
    //   true
    // );
    // // orgDetails.parentConfig = orgDetail;
    // this.pages = this.pages.set(Pages.ORG_DETAILS, orgDetails);
    // this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ORG_DETAILS);

    // org create
    const orgCreatePage = new Page(Permissions.NONE, Pages.ORG_CREATE);
    const orgCreate = new RouterConfig(orgCreatePage, "创建机构", true, true);
    orgCreate.parentConfig = orgs;
    this.pages = this.pages.set(Pages.ORG_CREATE, orgCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.ORG_CREATE);

    // ailesson
    const ailessonPage = new Page(Permissions.NONE, Pages.AI_LESSON);
    const ailesson = new RouterConfig(
      ailessonPage,
      "AI课程题库",
      true,
      true,
      true
    );
    ailesson.parentConfig = main;
    this.pages = this.pages.set(Pages.AI_LESSON, ailesson);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.AI_LESSON);

    // ailesson create
    const ailessonCreatePage = new Page(Permissions.NONE, Pages.LESSON_CREATE);
    const ailessonCreate = new RouterConfig(
      ailessonCreatePage,
      "添加ai题库",
      true,
      true
    );
    ailessonCreate.parentConfig = ailesson;
    this.pages = this.pages.set(Pages.LESSON_CREATE, ailessonCreate);
    this.pagesOrderedKeys = this.pagesOrderedKeys.push(Pages.LESSON_CREATE);
  }
}

export default Routers;
