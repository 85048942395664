import * as React from "react";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router";

/* third party */

/* scripts */
import { withRouter, connect, replace } from "core";

import View from "core/components/View";
import Content from "core/components/Content";
import Global from "core/env/global";
import { Layout, Icon, Modal } from "antd";
import "./index.less";
import Logo from "core/components/Logo";
import SideMenu from "./side_menu";
import NavRight from "./nav_right";
import Env from "core/env";
import { IFormProps } from "core/interfaces/icommon_props";
import AppGlobal from "app_global";
import Info from "./info";

interface IMainProps extends IFormProps {
  replace?: any;
}

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      replace
    },
    dispatch
  );
}

@withRouter()
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class Main extends View<IMainProps, {}> {
  public state: { [key: string]: any } = {
    collapsed: false,
    breakpoint: "md",
    content: true,
    editVisible: false
  };

  private routers: Route[] = [];

  constructor(props: IMainProps) {
    super(props);
    this.routers = Global.routers.getRouterComponents();
    this.onCollapsed = this.onCollapsed.bind(this);
    this.onUserInfo = this.onUserInfo.bind(this);
    this.onLoginOut = this.onLoginOut.bind(this);
  }

  private onCollapsed() {
    console.log("shou");
    this.setState({
      collapsed: !this.state.collapsed,
      breakpoint: !this.state.collapsed ? "sm" : "md",
      content: !this.state.content
    });
  }

  private onUserInfo() {
    console.log("我点个人中心了");
    this.setState({
      editVisible: true
    });
  }

  private onLoginOut() {
    localStorage.removeItem("isLogin");
    localStorage.removeItem("isPassword");
    localStorage.removeItem("token");
    localStorage.removeItem("tel");
    localStorage.removeItem("password");
    this.props.replace("");
  }

  public render() {
    return (
      <Content
        style={{
          display: "flex",
          position: "relative"
        }}
      >
        <Layout
          style={{
            width: "100%"
          }}
        >
          <Layout.Sider
            collapsible
            collapsed={this.state.collapsed}
            theme="light"
            // trigger={null}
            breakpoint={this.state.breakpoint}
            // collapsedWidth={ this.state.breakpoint === "sm" ? "0" : "80" }
            onCollapse={this.onCollapsed}
            style={{
              position: "relative",
              overflowX: "hidden"
            }}
          >
            <div
              style={{
                backgroundColor: "#F8C84C",
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowX: "hidden",
                position: "sticky",
                left: 0,
                top: 0,
                height: 64,
                maxWidth: 200,
                minWidth: 200,
                width: 200,
                zIndex: 2
              }}
            >
              <Logo
                style={{
                  width: 44,
                  height: 44,
                  left: 20
                }}
              />
              <span
                style={{
                  paddingLeft: 10,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: this.state.content ? "black" : "#F8C84C"
                }}
              >
                斑马 管理后台
              </span>
            </div>
            <SideMenu onCollapsed={this.onCollapsed} />
          </Layout.Sider>
          <Layout>
            <Layout.Header
              style={{
                backgroundColor: "#F8C84C",
                padding: 0,
                borderBottom: "solid 1px rgb(232, 232, 232)"
              }}
            >
              <NavRight
                style={{
                  float: "right",
                  height: 64,
                  padding: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                onUserInfo={this.onUserInfo}
                onloginOut={this.onLoginOut}
              />
            </Layout.Header>
            <Layout.Content>
              <Switch>{this.routers}</Switch>
            </Layout.Content>
            <Layout.Footer
              style={{
                display: "flex"
              }}
            >
              <div
                style={{
                  flex: 1
                }}
              >
                Copyright <Icon type="copyright" /> 2019 东乐(杭州)科技有限公司
              </div>
              <div
                style={{
                  flex: 1,
                  textAlign: "right"
                }}
              >
                v{Env.VERSION}({Env.BUILD})
              </div>
            </Layout.Footer>
          </Layout>
        </Layout>
        <Modal
          title={"个人资料"}
          visible={this.state.editVisible}
          maskClosable={false}
          width={600}
          closable={false}
          destroyOnClose
          afterClose={() => {}}
          footer={null}
        >
          <Info
            onSuccess={() => {
              this.setState(
                {
                  editVisible: false
                },
                () => {}
              );
            }}
            userInfo={AppGlobal.adminData}
            onCancel={() => {
              this.setState({
                editVisible: false
              });
            }}
          />
        </Modal>
      </Content>
    );
  }
}

export default Main;
