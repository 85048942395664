import { Permissions } from "../../enums/permissions";
import { Pages } from "../../enums/pages";

class AuthorizedElement {
  public permissionId: Permissions;
  public name: Pages;

  constructor(permissionId: Permissions, name: Pages) {
    this.permissionId = permissionId;
    this.name = name;
  }
}

export default AuthorizedElement;
