import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getActivityList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ACTIVITY_LIST, { params: params });
    yield put({
      type: Actions.ACTIVITY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ACTIVITY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchActivityList() {
  while (true) {
    const action = yield take(Actions.ACTIVITY_LIST.GET);
    yield fork(getActivityList, action.params);
  }
}


export function* getActivityDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ACTIVITY_DETAIL, { params: params });
    yield put({
      type: Actions.ACTIVITY_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ACTIVITY_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchActivityDetail() {
  while (true) {
    const action = yield take(Actions.ACTIVITY_DETAIL.GET);
    yield fork(getActivityDetail, action.params);
  }
}



export function* postActivityDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ACTIVITY_DELETE, {
      ...params
    });
    yield put({
      type: Actions.ACTIVITY_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ACTIVITY_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchActivityDelete() {
  while (true) {
    const action = yield take(Actions.ACTIVITY_DELETE.POST);
    yield fork(postActivityDelete, action.params);
  }
}



export function* postActivitySave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ACTIVITY_SAVE, {
      ...params
    });
    yield put({
      type: Actions.ACTIVITY_SAVE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ACTIVITY_SAVE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchActivitySave() {
  while (true) {
    const action = yield take(Actions.ACTIVITY_SAVE.POST);
    yield fork(postActivitySave, action.params);
  }
}
