import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "../";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* postAiAudioAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_AUDIO_ADD, { ...params });
    yield put({
      type: Actions.AI_AUIDO_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_AUIDO_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiAudioAdd() {
  while (true) {
    const action = yield take(Actions.AI_AUIDO_ADD.POST);
    yield fork(postAiAudioAdd, action.params);
  }
}

export function* postAiAudioDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_AUDIO_DELETE, { ...params });
    yield put({
      type: Actions.AI_AUDIO_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_AUDIO_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiAudioDelete() {
  while (true) {
    const action = yield take(Actions.AI_AUDIO_DELETE.POST);
    yield fork(postAiAudioDelete, action.params);
  }
}

export function* postAiAudioUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_AUDIO_UPDATE, { ...params });
    yield put({
      type: Actions.AI_AUDIO_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_AUDIO_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiAudioUpdate() {
  while (true) {
    const action = yield take(Actions.AI_AUDIO_UPDATE.POST);
    yield fork(postAiAudioUpdate, action.params);
  }
}
