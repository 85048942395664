import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* postClassesSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_CLASS_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_CLASS_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_CLASS_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchClassesSave() {
  while (true) {
    const action = yield take(Actions.CLASSES_CLASS_ADD.POST);
    yield fork(postClassesSave, action.params);
  }
}

export function* getClassesList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_CLASS_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_CLASS_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_CLASS_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchClassesList() {
  while (true) {
    const action = yield take(Actions.CLASSES_CLASS_LIST.GET);
    yield fork(getClassesList, action.params);
  }
}

export function* postClassesUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_CLASS_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_CLASS_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_CLASS_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchClassesUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_CLASS_UPDATE.POST);
    yield fork(postClassesUpdate, action.params);
  }
}

export function* postClassesDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_CLASS_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_CLASS_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_CLASS_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchClassesDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_CLASS_DELETE.POST);
    yield fork(postClassesDelete, action.params);
  }
}

export function* getLargeClassList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLargeClassList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_LIST.GET);
    yield fork(getLargeClassList, action.params);
  }
}

export function* postLargeClassUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLargeClassUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_UPDATE.POST);
    yield fork(postLargeClassUpdate, action.params);
  }
}

export function* postLargeClassSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLargeClassSave() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_ADD.POST);
    yield fork(postLargeClassSave, action.params);
  }
}

export function* postLargeClassDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLargeClassDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_DELETE.POST);
    yield fork(postLargeClassDelete, action.params);
  }
}

export function* getLcCourseDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcCourseDetail() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_DETAIL.GET);
    yield fork(getLcCourseDetail, action.params);
  }
}

export function* getLcCKlList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_CKL_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_CKL_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_CKL_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcCKlList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_CKL_LIST.GET);
    yield fork(getLcCKlList, action.params);
  }
}

export function* getLcCSubjectsList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_CSUBJECT_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_CSUBJECT_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_CSUBJECT_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcCSubjectsList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_CSUBJECT_LIST.GET);
    yield fork(getLcCSubjectsList, action.params);
  }
}

export function* postLcSchedulesSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_SCHEDULE_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSchedulesSave() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SCHEDULE_ADD.POST);
    yield fork(postLcSchedulesSave, action.params);
  }
}

export function* postLcSchedulesDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_SCHEDULE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSchedulesDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SCHEDULE_DELETE.POST);
    yield fork(postLcSchedulesDelete, action.params);
  }
}

export function* postLcSchedulesUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_SCHEDULE_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSchedulesUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SCHEDULE_UPDATE.POST);
    yield fork(postLcSchedulesUpdate, action.params);
  }
}

export function* getLcSchedulesList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_SCHEDULE_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SCHEDULE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcSchedulesList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SCHEDULE_LIST.GET);
    yield fork(getLcSchedulesList, action.params);
  }
}

export function* getLcAttendLogsList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_ATTEND_LOGS_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcAttendLogsList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_ATTEND_LOGS_LIST.GET);
    yield fork(getLcAttendLogsList, action.params);
  }
}

export function* postLcAttendLogsAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_ATTEND_LOGS_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcAttendLogsAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_ATTEND_LOGS_ADD.POST);
    yield fork(postLcAttendLogsAdd, action.params);
  }
}

export function* postLcAttendLogsDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(
      NetWork.post,
      API.CLASSES_LC_ATTEND_LOGS_DELETE,
      {
        ...params
      }
    );
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcAttendLogsDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_ATTEND_LOGS_DELETE.POST);
    yield fork(postLcAttendLogsDelete, action.params);
  }
}

export function* postLcAttendLogsUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(
      NetWork.post,
      API.CLASSES_LC_ATTEND_LOGS_UPDATE,
      {
        ...params
      }
    );
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_ATTEND_LOGS_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcAttendLogsUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_ATTEND_LOGS_UPDATE.POST);
    yield fork(postLcAttendLogsUpdate, action.params);
  }
}

export function* postLcChapterAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_CHAPTER_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcChapterAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_CHAPTER_ADD.POST);
    yield fork(postLcChapterAdd, action.params);
  }
}

export function* postLcChapterDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_CHAPTER_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcChapterDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_CHAPTER_DELETE.POST);
    yield fork(postLcChapterDelete, action.params);
  }
}

export function* postLcChapterUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_CHAPTER_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcChapterUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_CHAPTER_UPDATE.POST);
    yield fork(postLcChapterUpdate, action.params);
  }
}

export function* getLcChapterList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_CHAPTER_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_CHAPTER_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcChapterList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_CHAPTER_LIST.GET);
    yield fork(getLcChapterList, action.params);
  }
}

export function* postLcSubjectsAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_SUBJECT_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSubjectsAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SUBJECT_ADD.POST);
    yield fork(postLcSubjectsAdd, action.params);
  }
}

export function* postLcSubjectsDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_SUBJECT_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSubjectsDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SUBJECT_DELETE.POST);
    yield fork(postLcSubjectsDelete, action.params);
  }
}

export function* postLcSubjectsUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_SUBJECT_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSubjectsUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SUBJECT_UPDATE.POST);
    yield fork(postLcSubjectsUpdate, action.params);
  }
}

export function* getLcSubjectsDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_SUBJECT_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcSubjectsDetail() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SUBJECT_DETAIL.GET);
    yield fork(getLcSubjectsDetail, action.params);
  }
}

export function* getLcSubjectsList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_SUBJECT_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcSubjectsList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SUBJECT_LIST.GET);
    yield fork(getLcSubjectsList, action.params);
  }
}

export function* getLcsKlList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_SUBJECT_KL_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_KL_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_SUBJECT_KL_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcsKlList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_SUBJECT_KL_LIST.GET);
    yield fork(getLcsKlList, action.params);
  }
}

export function* postLcModulesAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_MODULES_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_MODULES_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_MODULES_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcModulesAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_MODULES_ADD.POST);
    yield fork(postLcModulesAdd, action.params);
  }
}

export function* postLcModulesDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_MODULES_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_MODULES_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_MODULES_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcModulesDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_MODULES_DELETE.POST);
    yield fork(postLcModulesDelete, action.params);
  }
}

export function* postLcModulesUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_MODULES_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_MODULES_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_MODULES_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcModulesUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_MODULES_UPDATE.POST);
    yield fork(postLcModulesUpdate, action.params);
  }
}

export function* getLcModulesList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_MODULES_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_MODULES_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_MODULES_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcModulesList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_MODULES_LIST.GET);
    yield fork(getLcModulesList, action.params);
  }
}

export function* getLcGameawardDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_GAMEAWARD_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcGameawardDetail() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_GAMEAWARD_DETAIL.GET);
    yield fork(getLcGameawardDetail, action.params);
  }
}

export function* postLcGameawardAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_GAMEAWARD_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcGameawardAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_GAMEAWARD_ADD.POST);
    yield fork(postLcGameawardAdd, action.params);
  }
}

export function* postLcGameawardDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_GAMEAWARD_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcGameawardDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_GAMEAWARD_DELETE.POST);
    yield fork(postLcGameawardDelete, action.params);
  }
}

export function* postLcGameawardUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_GAMEAWARD_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcGameawardUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_GAMEAWARD_UPDATE.POST);
    yield fork(postLcGameawardUpdate, action.params);
  }
}

export function* getLcGameawardList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_GAMEAWARD_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_GAMEAWARD_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcGameawardList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_GAMEAWARD_LIST.GET);
    yield fork(getLcGameawardList, action.params);
  }
}

export function* watchLcBoxawardDetail() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BOXAWARD_DETAIL.GET);
    yield fork(getLcBoxawardDetail, action.params);
  }
}

export function* getLcBoxawardDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_BOXAWARD_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}
export function* postLcBoxawardAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_BOXAWARD_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcBoxawardAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BOXAWARD_ADD.POST);
    yield fork(postLcBoxawardAdd, action.params);
  }
}

export function* postLcBoxawardDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_BOXAWARD_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcBoxawardDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BOXAWARD_DELETE.POST);
    yield fork(postLcBoxawardDelete, action.params);
  }
}

export function* postLcBoxawardUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_BOXAWARD_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcBoxawardUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BOXAWARD_UPDATE.POST);
    yield fork(postLcBoxawardUpdate, action.params);
  }
}

export function* getLcBoxawardList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_BOXAWARD_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BOXAWARD_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcBoxawardList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BOXAWARD_LIST.GET);
    yield fork(getLcBoxawardList, action.params);
  }
}

export function* postLcBgsoundAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_BGSOUND_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcBgsoundAdd() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BGSOUND_ADD.POST);
    yield fork(postLcBgsoundAdd, action.params);
  }
}

export function* postLcBgsoundDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_BGSOUND_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcBgsoundDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BGSOUND_DELETE.POST);
    yield fork(postLcBgsoundDelete, action.params);
  }
}

export function* postLcBgsoundUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_LC_BGSOUND_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcBgsoundUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BGSOUND_UPDATE.POST);
    yield fork(postLcBgsoundUpdate, action.params);
  }
}

export function* getLcBgsoundList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_LC_BGSOUND_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_LC_BGSOUND_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchLcBgsoundList() {
  while (true) {
    const action = yield take(Actions.CLASSES_LC_BGSOUND_LIST.GET);
    yield fork(getLcBgsoundList, action.params);
  }
}

// 题库关联的引导库
export function* getLcSubjectGuideDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.LC_SUBJECT_GUIDE_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* postLcSubjectGuideAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.LC_SUBJECT_GUIDE_ADD, {
      ...params
    });
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSubjectGuideAdd() {
  while (true) {
    const action = yield take(Actions.LC_SUBJECT_GUIDE_ADD.POST);
    yield fork(postLcSubjectGuideAdd, action.params);
  }
}

export function* postLcSubjectGuideUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.LC_SUBJECT_GUIDE_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSubjectGuideUpdate() {
  while (true) {
    const action = yield take(Actions.LC_SUBJECT_GUIDE_UPDATE.POST);
    yield fork(postLcSubjectGuideUpdate, action.params);
  }
}

export function* watchLcSubjectGuideDetail() {
  while (true) {
    const action = yield take(Actions.LC_SUBJECT_GUIDE_DETAIL.GET);
    yield fork(getLcSubjectGuideDetail, action.params);
  }
}

export function* postLcSubjectGuideDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.LC_SUBJECT_GUIDE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.LC_SUBJECT_GUIDE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchLcSubjectGuideDelete() {
  while (true) {
    const action = yield take(Actions.LC_SUBJECT_GUIDE_DELETE.POST);
    yield fork(postLcSubjectGuideDelete, action.params);
  }
}
