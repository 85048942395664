import * as React from "react";

import { IStyleProps } from "../interfaces/icommon_props";

class Content extends React.Component<IStyleProps> {
  public render() {
    const styles = {
      width: "100%",
      height: "100%",
      backgroundColor: "#f5f5f9",
      ...this.props.style
    };

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child ? (child as any) : <div />, {})
    );
    return (
      <div style={styles} className={this.props.className}>
        {childrenWithProps}
      </div>
    );
  }
}

export default Content;
