import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* postScheduleInsert(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_INSERT, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_INSERT.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_INSERT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleInsert() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_INSERT.POST);
    yield fork(postScheduleInsert, action.params);
  }
}

//列表
export function* getScheduleType(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SCHEDULE_COURES_SCHEDULE_TYPE, {
      params: params
    });
    yield put({
      type: Actions.SCHEDULE_COURES_SCHEDULE_TYPE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_COURES_SCHEDULE_TYPE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchScheduleType() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_COURES_SCHEDULE_TYPE.GET);
    yield fork(getScheduleType, action.params);
  }
}

//列表
export function* getAiCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SCHEDULE_COURES_AI_COURSE, {
      params: params
    });
    yield put({
      type: Actions.SCHEDULE_COURES_AI_COURSE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_COURES_AI_COURSE.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiCourse() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_COURES_AI_COURSE.GET);
    yield fork(getAiCourse, action.params);
  }
}



//机构恢复课表
export function* postScheduleRestartOrg(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_RESTART_ORG, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_RESTART_ORG.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_RESTART_ORG.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleRestartOrg() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_RESTART_ORG.POST);
    yield fork(postScheduleRestartOrg, action.params);
  }
}



//用户恢复课表
export function* postScheduleRestartUser(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_RESTART_USER, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_RESTART_USER.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_RESTART_USER.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleRestartUser() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_RESTART_USER.POST);
    yield fork(postScheduleRestartUser, action.params);
  }
}


//课程恢复排课
export function* postScheduleRestartCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_RESTART_COURSE, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_RESTART_COURSE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_RESTART_COURSE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleRestartCourse() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_RESTART_COURSE.POST);
    yield fork(postScheduleRestartCourse, action.params);
  }
}


//机构停课
export function* postScheduleStopOrg(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_STOP_ORG, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_STOP_ORG.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_STOP_ORG.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleStopOrg() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_STOP_ORG.POST);
    yield fork(postScheduleStopOrg, action.params);
  }
}


//用户停课
export function* postScheduleStopUser(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_STOP_USER, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_STOP_USER.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_STOP_USER.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleStopUser() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_STOP_USER.POST);
    yield fork(postScheduleStopUser, action.params);
  }
}


//课程停止排课
export function* postScheduleStopCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_STOP_COURSE, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_STOP_COURSE.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_STOP_COURSE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleStopCourse() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_STOP_COURSE.POST);
    yield fork(postScheduleStopCourse, action.params);
  }
}


export function* postScheduleCourseRetreat(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_COURSE_RETREAT, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_COURSE_RETREAT.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_COURSE_RETREAT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleCourseRetreat() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_COURSE_RETREAT.POST);
    yield fork(postScheduleCourseRetreat, action.params);
  }
}