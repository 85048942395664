import * as React from "react";

class Loading extends React.Component {
  public getContent() {
    const props = this.props;
    if (props.hasOwnProperty("error")) {
      return <div>error</div>;
    } else if (props.hasOwnProperty("timedOut")) {
      return <div>time out</div>;
    } else if (props.hasOwnProperty("pastDelay")) {
      return <div>loading</div>;
    }
    return null;
  }

  public render() {
    return <div>{this.getContent()}</div>;
  }
}

export default Loading;
