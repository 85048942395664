import Model from "./model";
import { Type } from "class-transformer";
import OrganizationRating from "./organization_rating";
import Area from "./area";

export default class Organization extends Model {
    public name?: string;
    public street?: string;
    public boss_name?: string;
    public boss_tel?: string;
    public contact_name?: string;
    public contact_tel?: string;
    public sales_name?: string;
    public sales_tel?: string;
    public remark?: string;
    public student_count?: number;
    public current_student_count?: number;
    public teacher_count?: number;
    public current_teacher_count?: number;
    public class_count?: number;
    public current_class_count?: number;

    @Type(() => Area)
    public province?: Area;

    @Type(() => Area)
    public city?: Area;

    @Type(() => Area)
    public area?: Area;

    @Type(() => OrganizationRating)
    public rating?: OrganizationRating;
}