import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getPackList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.PACK_LIST, { params: params });
    yield put({
      type: Actions.PACK_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchPackList() {
  while (true) {
    const action = yield take(Actions.PACK_LIST.GET);
    yield fork(getPackList, action.params);
  }
}

export function* getPackDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.PACK_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.PACK_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchPackDetail() {
  while (true) {
    const action = yield take(Actions.PACK_DETAIL.GET);
    yield fork(getPackDetail, action.params);
  }
}

export function* postPackUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.PACK_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.PACK_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchPackUpdate() {
  while (true) {
    const action = yield take(Actions.PACK_UPDATE.POST);
    yield fork(postPackUpdate, action.params);
  }
}

export function* postPackAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.PACK_ADD, {
      ...params
    });
    yield put({
      type: Actions.PACK_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchPackAdd() {
  while (true) {
    const action = yield take(Actions.PACK_ADD.POST);
    yield fork(postPackAdd, action.params);
  }
}

//用户课包
export function* getPackUserList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.PACK_USER_LIST, {
      params: params
    });
    yield put({
      type: Actions.PACK_USER_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_USER_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchPackUserList() {
  while (true) {
    const action = yield take(Actions.PACK_USER_LIST.GET);
    yield fork(getPackUserList, action.params);
  }
}

export function* getPackUserDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.PACK_USER_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.PACK_USER_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_USER_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchPackUserDetail() {
  while (true) {
    const action = yield take(Actions.PACK_USER_DETAIL.GET);
    yield fork(getPackUserDetail, action.params);
  }
}

export function* postPackUserDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.PACK_USER_DELETE, {
      ...params
    });
    yield put({
      type: Actions.PACK_USER_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_USER_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchPackUserDelete() {
  while (true) {
    const action = yield take(Actions.PACK_USER_DELETE.POST);
    yield fork(postPackUserDelete, action.params);
  }
}

export function* postPackUserAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.PACK_USER_ADD, {
      ...params
    });
    yield put({
      type: Actions.PACK_USER_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_USER_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchPackUserAdd() {
  while (true) {
    const action = yield take(Actions.PACK_USER_ADD.POST);
    yield fork(postPackUserAdd, action.params);
  }
}

export function* postPackUserRefund(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.PACK_USER_REFUND, {
      ...params
    });
    yield put({
      type: Actions.PACK_USER_REFUND.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.PACK_USER_REFUND.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchPackUserRefund() {
  while (true) {
    const action = yield take(Actions.PACK_USER_REFUND.POST);
    yield fork(postPackUserRefund, action.params);
  }
}
