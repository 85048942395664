import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

const reducer = (state = {}, action: any) => {
  const data: { [key: string]: any } = { ...state };
  if (action.type && action.response) {
    if (action.response.success && action.response.err_code === -1) {
      data[action.type] = {
        data: action.response.data,
        params: action.params
      };
    } else {
      data[action.type] = {
        data: action.response,
        params: action.params
      };
    }
    if (data[action.type]) data[action.type]["__processed"] = false;
  }
  return data;
};

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    bm: reducer
  });
