import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getCourseHas(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.COURSE_HAS, { params: params });
    yield put({
      type: Actions.COURSE_HAS.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.COURSE_HAS.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchCourseHas() {
  while (true) {
    const action = yield take(Actions.COURSE_HAS.GET);
    yield fork(getCourseHas, action.params);
  }
}


