// TODO: 当前因为使用此处变量名来找全路径，所以必须要确定值唯一，需要优化
export enum Pages {
  LOGIN = "login",
  MAIN = "main",
  DASHBOARD = "dashboard",

  USERS = "users",
  USER_DETAIL = "user_detail",
  USER_CREATE = "user_create",

  REPLY_RECORD = "reply_record",
  SUBJECT_RECORD = "subject_record",

  ALL_PRACTISES = "all_practises",
  ALL_PRACTISES_CREATE = "all_practises_create",
  // ALL_PRACTISES_DATEIL = "all_practises_detail",

  KNOWLEDGE = "knowledge",
  KNOWLEDGE_SAVE = "knowledge_save",
  KL_RANK = "kl_rank",
  KL_TYPE = "kl_type",
  LC_MODULES = "lc_modules",

  CLASSES = "classes", //班级管理

  ATTEND_LOGS = "attend_logs",
  LC_CHAPTER = "lc_chapter", //大班课课程 章节管理
  LARGE_CLASSES = "large_classes", //大班课课程
  LC_SCHEDULES = "lc_schedules", //大班课排课管理
  LC_ATTEND_LOGS = "lc_attend_logs", //大班课上课日志
  LC_SUBJECT = "lc_subject", // 大班课题库
  LC_BGSOUND = "lc_bgsound", // 大班课音乐背景
  LC_GAMEAWARD = "lc_gameaward", // 大班课游戏环节奖品
  LC_BOXAWARD = "lc_boxaward", // 大班课宝箱奖品
  LC_GAMEAWARD_SAVE = "lc_gameaward_save",
  LC_BOXAWARD_SAVE = "lc_boxaward_save",
  LC_SUBJECT_SAVE = "lc_subject_save",

  LARGE_CLASSES_SAVE = "lc_course_save", //添加或编辑课程页面
  ALL_LC_SUBJECT_CREATE = "lc_subject_create",

  PRACTISE_TAGS = "practise_tags",
  PRACTISE_CATEGORIES = "practise_categories",
  PRACTISE_DIFFICULTIES = "practise_difficulties",
  // PRACTISE_EVALUATIONS = "practise_evaluations",

  TAGS_GRADE = "tags_grade",
  TAGS_PROBLEM = "tags_problem",
  TAGS_CLASS = "tags_class",
  // TAGS_CLASS_PUBLIC = "tags_class_public",

  ALL_MELODIES = "all_melodies",
  ALL_MELODIES_CREATE = "all_melodies_create",
  ALL_MELODIES_EDIT = "all_melodies_edit",

  MELODY_GUIDES = "melody_guides",
  MELODY_GUIDES_CREATE = "melody_guides_create",
  MELODY_GUIDES_DETAIL = "melody_guides_detail",

  GUIDE_CATEGORY = "guide_category",

  COURSE_MATERIAL = "course_material",
  COURSE_CHAPTER = "course_chapter",
  COURSE_LESSON = "course_lesson",

  COURSES = "courses",
  SCHEDULES = "schedules",

  ACTIVITIES = "activities",
  // ACTIVITIESADD = "activities_add",
  ACTIVITIESUPDATE = "activities_update",
  // ACTIVITIESEDIT = "activities_edit",

  ADS = "ads",
  // ADS_CREATE = "ads_create",
  // ADS_DETAIL = "ads_detail",
  ADS_UPDATE = "ads_update",

  PACK = "pack",
  PACKMODULE = "pack_module",

  ARTICLES = "articles",
  ARTICLES_CLASS = "articles_class",
  // ARTICLE_EDITS = "articles_edit",
  ARTICLES_ADD = "articles_add",
  // ARTICLES_UPDATE = "articles_update",

  FAQS = "faqs",
  // FAQ_CREATE = "faq_create",
  FAQ_EDIT = "faq_edit",

  ADVISES = "advises",
  ADVISES_DETAIL = "advises_detail",

  SOLUTIONS = "solutions",
  // SOLUTION_CREATE = "solution_create",
  // SOLUTION_EDIT = "solution_edit",
  SOLUTIONS_UPDATE = "solution_update",

  NOTIFICATIONS = "notifications",
  // NOTIFIUPDATE = "notifi_update",
  NOTIFIADD = "notifi_add",
  // NOTIFI_EDIT = "notifi_edit",

  VIDEOS = "videos",
  // VIDEOS_DETAIL = "videos_detail",

  OPERATING = "operating",

  AUDIOS = "audios",

  ABOVE_CLASS = "above_class",

  CONFIGURATIONS = "configurations",
  AGREEMENTS = "agreements",
  // AGREEMENTSDETAIL  = "agreement_details",
  // AGREEMENTSADD  = "agreement_add",
  AGREEMENTS_COPY = "agreement_copy",

  ADMINS = "admins",

  LOGS = "logs",

  ORGS = "orgs",
  // ORG_DETAIL = 'org_detail',
  ORG_CREATE = "org_create",
  // ORG_DETAILS = 'org_details',
  ORG_EDIT = "org_edit",

  AI = "ai",
  AI_DETAIL = "ai_detail",
  AI_CLASS_ADD = "ai_class_add",
  AI_LESSON = "ai_lesson",
  LESSON_CREATE = "lesson_create"
}
