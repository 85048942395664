import * as React from 'react';
import View from 'core/components/View';
import { bindActionCreators } from 'redux';
import { FormComponentProps } from "antd/lib/form";
import { withRouter, connect } from "core";
import Content from 'core/components/Content';
import Actions from 'core/net/actions';
import * as CryptoJS from "crypto-js";
import { JSEncrypt } from "jsencrypt";
import {
  Form,
  Input,
  Button,
  message,
  notification,

} from 'antd';
import Global from 'core/env/global';
import Admin from 'models/admin';
import Utils from 'core/utils/utils';
import Password from 'antd/lib/input/Password';
// import Password from 'antd/lib/input/Password';
// const { TextArea } = Input;

interface IInfoProps extends FormComponentProps {
  postAdminUpdate?: (params: { [key: string]: any }) => void,
  onCancel?: () => void,
  onSuccess?: () => void,
  updateUser?: any,
  userInfo?: Admin,
  
}

function mapStateToProps(state: any) {
  return {
    updateUser: state.bm[Actions.ADMIN_UPDATE.RAW],
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    postAdminUpdate: (params: { [key: string]: any }) => {
      return { type: Actions.ADMIN_UPDATE.POST, params: params }
    },
  }, dispatch);
}

@withRouter()
@connect(mapStateToProps, mapDispatchToProps)
class Info extends View<IInfoProps, {}> {
  state: { [key: string]: any } = {
    realname: null,
    telephone: null,
    password: null,
    remark: null,
    role_id: 0,
    is_locked: 0,
    id: null,
    check: null,
  }

  constructor(props: IInfoProps) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  private formItemLayout: any = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    }
  };
  private onSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {

        if (this.state.telephone && this.state.telephone.length !== 11) {
          message.info("请输入正确的手机号");
          return;
        }
        let psw = null;
        if (this.state.password) {
          console.log(this.state.password);
          if (this.state.password.length < 6 || this.state.password.length > 30) {
            message.info("密码最大只能6~30位");
            return;
          }
          let password = this.state.password;
          password = CryptoJS.MD5(password).toString();
          password = CryptoJS.MD5(password).toString();
          password = CryptoJS.MD5(password).toString();
          password = CryptoJS.MD5(password).toString();
          const rsaEncrypt = new JSEncrypt();
          rsaEncrypt.setPublicKey(Global.PUB_RSA);
          password = rsaEncrypt.encrypt(password);
          psw = password;
        }
        let remark = this.state.remark;
        if (remark && remark.length > 200) {
          message.info("备注不能超过200字");
          return;
        }

        if (this.props.postAdminUpdate) {
          const params = {
            "id": this.state.id,
          };
          if (this.props.userInfo) {
            if (this.state.realname) params['realname'] = this.state.realname;
            if (this.state.telephone) params['telephone'] = this.state.telephone;
            if (this.state.role_id) params['role_id'] = this.state.role_id;
            if (this.state.is_locked) params['is_locked'] = this.state.is_locked;
            if (remark) params['remark'] = remark;
            if (this.state.org_id) params['org_id'] = this.state.org_id;
          }
          if (this.state.password) params['password'] = psw;
          if (this.state.teacher_id) params['teacher_id'] = this.state.teacher_id;
          console.log(params)
          this.props.postAdminUpdate(params);
        }
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    let obj = {};
    if (props.updateUser && !props.updateUser.__processed) {
      console.log(props.updateUser)
      if (props.updateUser.err_code !== -1) {
      } else {
        notification.destroy();
        notification.error({
          message: "更新管理员资料失败",
          description: props.updateUser.err_msg,
        });
      }
    }
    return obj;
  }

  componentDidMount() {
    if (this.props.userInfo) {
      this.setState({
        id: this.props.userInfo.id,
      })
      const config = {
        telephone: this.props.userInfo.telephone,
        realname: this.props.userInfo.realname,
        org_id: this.props.userInfo.org_id,
        role_id: String(this.props.userInfo.role_id),
        teacher_id: this.props.userInfo.teacher_id,
        is_locked: String(this.props.userInfo.is_locked),
        remark: this.props.userInfo.remark
      };
      this.props.form.setFieldsValue(config);
    }
  }

  componentDidUpdate() {
    if (this.props.updateUser && !this.props.updateUser.__processed) {
      this.props.updateUser.__processed = true;
      console.log(this.props.updateUser);
      if (this.props.updateUser.data === null) {
        if (this.props.onSuccess) this.props.onSuccess();
        notification.success({
          message: "提示",
          description: "更新管理员资料成功",
        });
      } else {
        notification.error({
          message: "提示",
          description: this.props.updateUser.data.err_msg,
        });
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content
        style={{
          position: "relative",
          height: "100%",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <Form onSubmit={this.onSubmit} {...this.formItemLayout}>
          <Form.Item
            label="真实姓名"
          >
            {
              getFieldDecorator("realname", {
                rules: [
                  {
                    required: false,
                    message: "请输入真实姓名"
                  }
                ]
              })(
                <Input
                  disabled = {true}
                  placeholder="请输入真实姓名"
                  onChange={(text) => {
                    const e = text.target.value;
                    this.setState({
                      realname: Utils.trim(e)
                    })
                  }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="手机号码"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("telephone", {
                rules: [
                  {
                    required: false,
                    message: "请输入手机号码"
                  }
                ]
              })(
                <Input
                  disabled = {true}
                  placeholder="请输入手机号码"
                  onChange={(text) => {
                    const e = text.target.value;
                    this.setState({
                      telephone: Utils.trim(e)
                    })
                  }}
                />
              )
            }
          </Form.Item>
          <Form.Item
            label="登录密码"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "请输入登录密码"
                  }
                ]
              })(               
                <Password
                  placeholder="请输入登录密码"
                  onChange={(text) => {
                    const e = text.target.value;
                    this.setState({
                      password: Utils.trim(e)
                    })
                  }}
                />
              )
            }
          </Form.Item>

          {/* <Form.Item
            label="角色"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("role_id", {
                rules: [
                  {
                    required: false,
                    message: "请选择管理员类型"
                  }
                ]
              })(
                <h4>{this.props.userInfo ? (this.props.userInfo.role_id === 0 ? "超级管理员" : "") : ""}</h4>
              )
            }
          </Form.Item> */}
          {/* <Form.Item
            label="绑定老师"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("teacher_id", {
                rules: [
                  {
                    required: false,
                    message: "请选择绑定老师"
                  }
                ]
              })(
                <h4>{this.props.userInfo ? (this.props.userInfo.teacher ? this.props.userInfo.teacher.realname : "") : ""}</h4>
              )
            }
          </Form.Item> */}
          {/* <Form.Item
            label="绑定机构"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("org_id", {
                rules: [
                  {
                    required: false,
                    message: "请选择绑定机构"
                  }
                ]
              })(
                <h4>{this.props.userInfo ? (this.props.userInfo.teacher ? this.props.userInfo.teacher.realname : "") : ""}</h4>
              )
            }
          </Form.Item> */}
          {/* <Form.Item
            label="是否锁定"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("is_locked", {
                rules: [
                  {
                    required: false,
                    message: "请输入手机号码"
                  }
                ]
              })(
                <h4>{this.props.userInfo ? (Number(this.props.userInfo.is_locked) === 1 ? "是" : "否") : "否"}</h4>
              )
            }
          </Form.Item> */}
          {/* <Form.Item
            label="备注"
            {...this.formItemLayout}
          >
            {
              getFieldDecorator("remark", {
                rules: [
                  {
                    required: false,
                    message: "请输入入备注"
                  }
                ]
              })(
                <TextArea
                  id="remark"
                  placeholder="请输入备注"
                  autosize={{ minRows: 5, maxRows: 5 }}
                  onChange={(text) => {
                    const e = text.target.value;
                    this.setState({
                      remark: Utils.trim(e)
                    })
                  }}
                />
              )
            }
          </Form.Item> */}
          <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
            <Button
              onClick={this.props.onCancel}
            >
              取消
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "20px" }}
              // onClick = { this.onHandleSubmit }
              htmlType="submit"
            >
              提交修改
            </Button>
          </div>
        </Form>
      </Content>
    );
  }
}

export default Form.create<IInfoProps>()(Info);