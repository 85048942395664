class Action {
  public RAW: string = "";
  public GET: string = "";
  public LOCAL: string = "";
  public POST: string = "";
  public ERROR: string = "";

  constructor(rawValue: string) {
    this.RAW = rawValue;
    this.LOCAL = `local_${this.RAW}`;
    this.GET = `get_${this.RAW}`;
    this.POST = `post_${this.RAW}`;
    this.ERROR = `error_${this.RAW}`;
  }
}

export default Action;
