import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getAiLessonList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AILESSON_LIST, {
      params: params
    });
    yield put({
      type: Actions.AILESSON_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AILESSON_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiLessonList() {
  while (true) {
    const action = yield take(Actions.AILESSON_LIST.GET);
    yield fork(getAiLessonList, action.params);
  }
}

export function* postAiLessonCreate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AILESSON_CREATE, {
      ...params
    });
    yield put({
      type: Actions.AILESSON_CREATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AILESSON_CREATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiLessonCreate() {
  while (true) {
    const action = yield take(Actions.AILESSON_CREATE.POST);
    yield fork(postAiLessonCreate, action.params);
  }
}

export function* postAiLessonUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AILESSON_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AILESSON_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AILESSON_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiLessonUpdate() {
  while (true) {
    const action = yield take(Actions.AILESSON_UPDATE.POST);
    yield fork(postAiLessonUpdate, action.params);
  }
}

export function* getAiLessonDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AILESSON_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.AILESSON_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AILESSON_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiLessonDetail() {
  while (true) {
    const action = yield take(Actions.AILESSON_DETAIL.GET);
    yield fork(getAiLessonDetail, action.params);
  }
}

export function* postAiLessonDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AILESSON_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AILESSON_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AILESSON_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiLessonDelete() {
  while (true) {
    const action = yield take(Actions.AILESSON_DELETE.POST);
    yield fork(postAiLessonDelete, action.params);
  }
}
