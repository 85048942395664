import { take, fork, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* doLogin(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.LOGIN, params);
    yield put({
      type: Actions.LOGIN.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.LOGIN.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchDoLogin() {
  while (true) {
    const action = yield take(Actions.LOGIN.POST);
    yield fork(doLogin, action.params);
  }
}
