import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getSubjectTagList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_TAGS_LIST, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_TAGS_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_TAGS_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectTagList() {
  while (true) {
    const action = yield take(Actions.SUBJECT_TAGS_LIST.GET);
    yield fork(getSubjectTagList, action.params);
  }
}

export function* getSubjectTagDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SUBJECT_TAGS_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.SUBJECT_TAGS_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_TAGS_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchSubjectTagDetail() {
  while (true) {
    const action = yield take(Actions.SUBJECT_TAGS_DETAIL.GET);
    yield fork(getSubjectTagDetail, action.params);
  }
}

export function* postSubjectTagUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_TAGS_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_TAGS_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_TAGS_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectTagUpdate() {
  while (true) {
    const action = yield take(Actions.SUBJECT_TAGS_UPDATE.POST);
    yield fork(postSubjectTagUpdate, action.params);
  }
}

export function* postSubjectTagSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_TAGS_SAVE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_TAGS_SAVE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_TAGS_SAVE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectTagSave() {
  while (true) {
    const action = yield take(Actions.SUBJECT_TAGS_SAVE.POST);
    yield fork(postSubjectTagSave, action.params);
  }
}

export function* postSubjectTagDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SUBJECT_TAGS_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SUBJECT_TAGS_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SUBJECT_TAGS_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchSubjectTagDelete() {
  while (true) {
    const action = yield take(Actions.SUBJECT_TAGS_DELETE.POST);
    yield fork(postSubjectTagDelete, action.params);
  }
}

export function* postTagsCategoryDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_CATEGORY_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_CATEGORY_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_CATEGORY_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsCategoryDelete() {
  while (true) {
    const action = yield take(Actions.TAGS_CATEGORY_DELETE.POST);
    yield fork(postTagsCategoryDelete, action.params);
  }
}


export function* postTagsCategoryUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_CATEGORY_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_CATEGORY_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_CATEGORY_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsCategoryUpdate() {
  while (true) {
    const action = yield take(Actions.TAGS_CATEGORY_UPDATE.POST);
    yield fork(postTagsCategoryUpdate, action.params);
  }
}


export function* postTagsCategoryAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_CATEGORY_ADD, {
      ...params
    });
    yield put({
      type: Actions.TAGS_CATEGORY_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_CATEGORY_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsCategoryAdd() {
  while (true) {
    const action = yield take(Actions.TAGS_CATEGORY_ADD.POST);
    yield fork(postTagsCategoryAdd, action.params);
  }
}


export function* getTagsCategoryList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TAGS_CATEGORY_LIST, {
      params: params
    });
    yield put({
      type: Actions.TAGS_CATEGORY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_CATEGORY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTagsCategoryList() {
  while (true) {
    const action = yield take(Actions.TAGS_CATEGORY_LIST.GET);
    yield fork(getTagsCategoryList, action.params);
  }
}


export function* getTagsLevelList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TAGS_LEVEL_LIST, {
      params: params
    });
    yield put({
      type: Actions.TAGS_LEVEL_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_LEVEL_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTagsLevelList() {
  while (true) {
    const action = yield take(Actions.TAGS_LEVEL_LIST.GET);
    yield fork(getTagsLevelList, action.params);
  }
}




export function* postTagsLevelAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_LEVEL_ADD, {
      ...params
    });
    yield put({
      type: Actions.TAGS_LEVEL_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_LEVEL_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsLevelAdd() {
  while (true) {
    const action = yield take(Actions.TAGS_LEVEL_ADD.POST);
    yield fork(postTagsLevelAdd, action.params);
  }
}

export function* postTagsLevelDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_LEVEL_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_LEVEL_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_LEVEL_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsLevelDelete() {
  while (true) {
    const action = yield take(Actions.TAGS_LEVEL_DELETE.POST);
    yield fork(postTagsLevelDelete, action.params);
  }
}



export function* postTagsLevelUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_LEVEL_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_LEVEL_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_LEVEL_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsLevelUpdate() {
  while (true) {
    const action = yield take(Actions.TAGS_LEVEL_UPDATE.POST);
    yield fork(postTagsLevelUpdate, action.params);
  }
}




export function* postTagsProblemDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_PROBLEM_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_PROBLEM_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_PROBLEM_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsProblemDelete() {
  while (true) {
    const action = yield take(Actions.TAGS_PROBLEM_DELETE.POST);
    yield fork(postTagsProblemDelete, action.params);
  }
}




export function* postTagsProblemUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_PROBLEM_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_PROBLEM_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_PROBLEM_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsProblemUpdate() {
  while (true) {
    const action = yield take(Actions.TAGS_PROBLEM_UPDATE.POST);
    yield fork(postTagsProblemUpdate, action.params);
  }
}


export function* postTagsProblemAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_PROBLEM_ADD, {
      ...params
    });
    yield put({
      type: Actions.TAGS_PROBLEM_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_PROBLEM_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagsProblemAdd() {
  while (true) {
    const action = yield take(Actions.TAGS_PROBLEM_ADD.POST);
    yield fork(postTagsProblemAdd, action.params);
  }
}




export function* getTagsProblemList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TAGS_PROBLEM_LIST, {
      params: params
    });
    yield put({
      type: Actions.TAGS_PROBLEM_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_PROBLEM_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTagsProblemList() {
  while (true) {
    const action = yield take(Actions.TAGS_PROBLEM_LIST.GET);
    yield fork(getTagsProblemList, action.params);
  }
}



export function* getTagList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.TAGS_LIST, {
      params: params
    });
    yield put({
      type: Actions.TAGS_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchTagList() {
  while (true) {
    const action = yield take(Actions.TAGS_LIST.GET);
    yield fork(getTagList, action.params);
  }
}



export function* postTagUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagUpdate() {
  while (true) {
    const action = yield take(Actions.TAGS_UPDATE.POST);
    yield fork(postTagUpdate, action.params);
  }
}


export function* postTagDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_DELETE, {
      ...params
    });
    yield put({
      type: Actions.TAGS_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagDelete() {
  while (true) {
    const action = yield take(Actions.TAGS_DELETE.POST);
    yield fork(postTagDelete, action.params);
  }
}


export function* postTagAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.TAGS_ADD, {
      ...params
    });
    yield put({
      type: Actions.TAGS_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.TAGS_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchTagAdd() {
  while (true) {
    const action = yield take(Actions.TAGS_ADD.POST);
    yield fork(postTagAdd, action.params);
  }
}