import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getKnowledgeList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_KNOWLEDGE_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchKnowledgeList() {
  while (true) {
    const action = yield take(Actions.CLASSES_KNOWLEDGE_LIST.GET);
    yield fork(getKnowledgeList, action.params);
  }
}

export function* getKnowledgeDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_KNOWLEDGE_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchKnowledgeDetail() {
  while (true) {
    const action = yield take(Actions.CLASSES_KNOWLEDGE_DETAIL.GET);
    yield fork(getKnowledgeDetail, action.params);
  }
}

export function* postKnowledgeUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KNOWLEDGE_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKnowledgeUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_KNOWLEDGE_UPDATE.POST);
    yield fork(postKnowledgeUpdate, action.params);
  }
}

export function* postKnowledgeSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KNOWLEDGE_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKnowledgeSave() {
  while (true) {
    const action = yield take(Actions.CLASSES_KNOWLEDGE_ADD.POST);
    yield fork(postKnowledgeSave, action.params);
  }
}

export function* postKnowledgeDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KNOWLEDGE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KNOWLEDGE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKnowledgeDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_KNOWLEDGE_DELETE.POST);
    yield fork(postKnowledgeDelete, action.params);
  }
}

export function* postKlRankSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KL_RANK_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KL_RANK_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_RANK_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKlRankSave() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_RANK_ADD.POST);
    yield fork(postKlRankSave, action.params);
  }
}

export function* postKlRankDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KL_RANK_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KL_RANK_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_RANK_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKlRankDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_RANK_DELETE.POST);
    yield fork(postKlRankDelete, action.params);
  }
}

export function* postKlRankUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KL_RANK_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KL_RANK_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_RANK_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKlRankUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_RANK_UPDATE.POST);
    yield fork(postKlRankUpdate, action.params);
  }
}

export function* getKlRankList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_KL_RANK_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_KL_RANK_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_RANK_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchKlRankList() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_RANK_LIST.GET);
    yield fork(getKlRankList, action.params);
  }
}

export function* getKlTypesList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.CLASSES_KL_TYPE_LIST, {
      params: params
    });
    yield put({
      type: Actions.CLASSES_KL_TYPE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_TYPE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchKlTypesList() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_TYPE_LIST.GET);
    yield fork(getKlTypesList, action.params);
  }
}

export function* postKlTypesSave(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KL_TYPE_ADD, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KL_TYPE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_TYPE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKlTypesSave() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_TYPE_ADD.POST);
    yield fork(postKlTypesSave, action.params);
  }
}

export function* postKlTypesDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KL_TYPE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KL_TYPE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_TYPE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKlTypesDelete() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_TYPE_DELETE.POST);
    yield fork(postKlTypesDelete, action.params);
  }
}

export function* postKlTypesUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.CLASSES_KL_TYPE_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.CLASSES_KL_TYPE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.CLASSES_KL_TYPE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchKlTypesUpdate() {
  while (true) {
    const action = yield take(Actions.CLASSES_KL_TYPE_UPDATE.POST);
    yield fork(postKlTypesUpdate, action.params);
  }
}
