import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getNotification(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get,API.NOTIFICATION_LIST, {
      params: params
    });
    yield put({
      type: Actions.NOTIFICATION_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.NOTIFICATION_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchNotificationList() {
  while (true) {
    const action = yield take(Actions.NOTIFICATION_LIST.GET);
    yield fork(getNotification, action.params);
  }
}

export function* getNotificationClient(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get,API.NOTIFICATION_CLIENT, {
      params: params
    });
    yield put({
      type: Actions.NOTIFICATION_CLIENT.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.NOTIFICATION_CLIENT.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchNotificationClient() {
  while (true) {
    const action = yield take(Actions.NOTIFICATION_CLIENT.GET);
    yield fork(getNotificationClient, action.params);
  }
}

// 删除
export function* postNotificationDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.NOTIFICATION_DELETE, {
      ...params
    });
    yield put({
      type: Actions.NOTIFICATION_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.NOTIFICATION_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchNotificationDelete() {
  while (true) {
    const action = yield take(Actions.NOTIFICATION_DELETE.POST);
    yield fork(postNotificationDelete, action.params);
  }
}


// 详情
export function* getNotificationDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.NOTIFICATION_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.NOTIFICATION_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.NOTIFICATION_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchNotificationDetail() {
  while (true) {
    const action = yield take(Actions.NOTIFICATION_DETAIL.GET);
    yield fork(getNotificationDetail, action.params);
  }
}


//添加
export function* postNotificationAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.NOTIFICATION_ADD, { ...params });
    yield put({
      type: Actions.NOTIFICATION_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.NOTIFICATION_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchNotificationAdd() {
  while (true) {
    const action = yield take(Actions.NOTIFICATION_ADD.POST);
    yield fork(postNotificationAdd, action.params);
  }
}


//修改
export function* postNotificationUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.NOTIFICATION_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.NOTIFICATION_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.NOTIFICATION_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchNotificationUpdate() {
  while (true) {
    const action = yield take(Actions.NOTIFICATION_UPDATE.POST);
    yield fork(postNotificationUpdate, action.params);
  }
}


