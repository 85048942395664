import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getStsList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.STS, { params: params });
    yield put({
      type: Actions.STS.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.STS.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchtestList() {
  while (true) {
    const action = yield take(Actions.STS.GET);
    yield fork(getStsList, action.params);
  }
}
