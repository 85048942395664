import Model from "./model";
import { Type } from "class-transformer";
import TeacherType from "./teacher_type";
import Area from "./area";

export default class User extends Model {
  public uid?: string;
  public telephone?: string;
  public realname?: string;
  public avatar?: string;
  public background?: string;
  public gender?: number;
  public education?: string;
  public description?: string;
  public birthday?: string;
  public from?: number;
  public regist_time?: string;
  public age?: number;
  public school_time?: string;
  public school_age?: number;
  public org_id?: number;
  public number?: string;
  public role?: number;
  public main_teacher_id?: string;
  public prev_auto_assign_date?: string;
  public next_auto_assign_date?: string;
  public remark?: string;
  public replies_count?: number;
  public ability_id?: number;

  @Type(() => Area)
  public province?: Area;

  @Type(() => Area)
  public city?: Area;

  @Type(() => Area)
  public area?: Area;

  @Type(() => User)
  public main_teacher?: User;

  @Type(() => TeacherType)
  public teacher_type?: TeacherType;
}
