import Axios from "axios";
import Path from "./path";

class NetWork {
  private static config = {
    baseURL: Path.BASE_URL,
    headers: {
      Authorization: localStorage.getItem("token")
    }
  };
  public static get = Axios.create(NetWork.config).get;
  public static post = Axios.create(NetWork.config).post;

  public static refreshConfig() {
    let obj: any = {
      baseURL: Path.BASE_URL,
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    NetWork.config = obj;
    NetWork.get = Axios.create(NetWork.config).get;
    NetWork.post = Axios.create(NetWork.config).post;
  }
}

export default NetWork;
