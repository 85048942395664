import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";


export function* getSocketRoomList(params: { [key: string]: any }) {
    try {
      const response = yield call(NetWork.get, API.SOCKET_ROOM_LIST, { params: params });
      yield put({
        type: Actions.SOCKET_ROOM_LIST.RAW,
        response: plainToClass(Response, response.data),
        params: params
      });
    } catch (e) {
      yield put({
        type: Actions.SOCKET_ROOM_LIST.ERROR,
        response: ErrorBuilder.local(e),
        params: params
      });
    }
  }
  
  export function* watchSocketRoomList() {
    while (true) {
      const action = yield take(Actions.SOCKET_ROOM_LIST.GET);
      yield fork(getSocketRoomList, action.params);
    }
  }


  export function* getSocketRoomClient(params: { [key: string]: any }) {
    try {
      const response = yield call(NetWork.get, API.SOCKET_ROOM_CLIENT, { params: params });
      yield put({
        type: Actions.SOCKET_ROOM_CLIENT.RAW,
        response: plainToClass(Response, response.data),
        params: params
      });
    } catch (e) {
      yield put({
        type: Actions.SOCKET_ROOM_CLIENT.ERROR,
        response: ErrorBuilder.local(e),
        params: params
      });
    }
  }
  
  export function* watchSocketRoomClient() {
    while (true) {
      const action = yield take(Actions.SOCKET_ROOM_CLIENT.GET);
      yield fork(getSocketRoomClient, action.params);
    }
  }

  export function* postSocketRoomClose(params: { [key: string]: any }) {
    try {
      const response = yield call(NetWork.post, API.SOCKET_ROOM_CLOSE, {
        ...params
      });
      yield put({
        type: Actions.SOCKET_ROOM_CLOSE.RAW,
        response: plainToClass(Response, response.data),
        params: params
      });
    } catch (e) {
      yield put({
        type: Actions.SOCKET_ROOM_CLOSE.ERROR,
        response: ErrorBuilder.local(e)
      });
    }
  }
  
  export function* watchSocketRoomClose() {
    while (true) {
      const action = yield take(Actions.SOCKET_ROOM_CLOSE.POST);
      yield fork(postSocketRoomClose, action.params);
    }
  }


  export function* postSocketUserClose(params: { [key: string]: any }) {
    try {
      const response = yield call(NetWork.post, API.SOCKET_USER_CLOSE, {
        ...params
      });
      yield put({
        type: Actions.SOCKET_USER_CLOSE.RAW,
        response: plainToClass(Response, response.data),
        params: params
      });
    } catch (e) {
      yield put({
        type: Actions.SOCKET_USER_CLOSE.ERROR,
        response: ErrorBuilder.local(e)
      });
    }
  }
  
  export function* watchSocketUserClose() {
    while (true) {
      const action = yield take(Actions.SOCKET_USER_CLOSE.POST);
      yield fork(postSocketUserClose, action.params);
    }
  }