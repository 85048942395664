enum Path {
  // BASE_URL = "//dev.inner.doyure.com/xxxx6666/pf",
  // BASE_URL = "//pf.test.com",
  BASE_URL = "//pfapi.doyure.com",
  // BASE_URL                                        = "//192.168.3.14:3898",
  // BASE_URL                                        = "//api.org.doyure.com",

  AREA_JSON = "//static.doyure.com/data/area.json"
}

export default Path;
