import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

export function* getUserList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_LIST, { params: params });
    yield put({
      type: Actions.USER_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.USER_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchUserList() {
  while (true) {
    const action = yield take(Actions.USER_LIST.GET);
    yield fork(getUserList, action.params);
  }
}

export function* getUserDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.USER_DETAIL.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_DETAIL.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserDetail() {
  while (true) {
    const action = yield take(Actions.USER_DETAIL.GET);
    yield fork(getUserDetail, action.params);
  }
}

export function* getCourses(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.COURSES, { params: params });
    yield put({
      type: Actions.COURSES.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.COURSES.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchCourses() {
  while (true) {
    const action = yield take(Actions.COURSES.GET);
    yield fork(getCourses, action.params);
  }
}

export function* postAddSchedule(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADD_SCHEDULE, { ...params });
    yield put({
      type: Actions.ADD_SCHEDULE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADD_SCHEDULE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAddSchedule() {
  while (true) {
    const action = yield take(Actions.ADD_SCHEDULE.POST);
    yield fork(postAddSchedule, action.params);
  }
}

export function* postUpdateSchedule(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.UPDATE_SCHEDULE, {
      ...params
    });
    yield put({
      type: Actions.UPDATE_SCHEDULE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.UPDATE_SCHEDULE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUpdateSchedule() {
  while (true) {
    const action = yield take(Actions.UPDATE_SCHEDULE.POST);
    yield fork(postUpdateSchedule, action.params);
  }
}

export function* postAdjustCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.ADJUST_COURSE, { ...params });
    yield put({
      type: Actions.ADJUST_COURSE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ADJUST_COURSE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAdjustCourse() {
  while (true) {
    const action = yield take(Actions.ADJUST_COURSE.POST);
    yield fork(postAdjustCourse, action.params);
  }
}

export function* getAbsentList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ABSENT_LIST, {
      params: params
    });
    yield put({
      type: Actions.ABSENT_LIST.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.ABSENT_LIST.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAbsentList() {
  while (true) {
    const action = yield take(Actions.ABSENT_LIST.GET);
    yield fork(getAbsentList, action.params);
  }
}

export function* postManualFinishCourse(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.MANUAL_FINISH, { ...params });
    yield put({
      type: Actions.MANUAL_FINISH.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.MANUAL_FINISH.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchManualFinishCourse() {
  while (true) {
    const action = yield take(Actions.MANUAL_FINISH.POST);
    yield fork(postManualFinishCourse, action.params);
  }
}

export function* getScheduleList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SCHEDULE_LIST, {
      params: params
    });
    yield put({
      type: Actions.SCHEDULE_LIST.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_LIST.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleList() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_LIST.GET);
    yield fork(getScheduleList, action.params);
  }
}

export function* getScheduleSelect(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.SCHEDULE_SELECT, {
      params: params
    });
    yield put({
      type: Actions.SCHEDULE_SELECT.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_SELECT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleSelect() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_SELECT.GET);
    yield fork(getScheduleSelect, action.params);
  }
}

export function* postScheduleDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.SCHEDULE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.SCHEDULE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SCHEDULE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchScheduleDelete() {
  while (true) {
    const action = yield take(Actions.SCHEDULE_DELETE.POST);
    yield fork(postScheduleDelete, action.params);
  }
}

export function* postUpdateUser(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.USER_UPDATE, { ...params });
    yield put({
      type: Actions.USER_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchPostUpdateUser() {
  while (true) {
    const action = yield take(Actions.USER_UPDATE.POST);
    yield fork(postUpdateUser, action.params);
  }
}

export function* getUserGenStudent(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_GEN_STUDENT, {
      params: params
    });
    yield put({
      type: Actions.USER_GEN_STUDENT.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_GEN_STUDENT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserGenStudent() {
  while (true) {
    const action = yield take(Actions.USER_GEN_STUDENT.GET);
    yield fork(getUserGenStudent, action.params);
  }
}

export function* getUserGenTeacher(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_GEN_TEACHER, {
      params: params
    });
    yield put({
      type: Actions.USER_GEN_TEACHER.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_GEN_TEACHER.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserGenTeacher() {
  while (true) {
    const action = yield take(Actions.USER_GEN_TEACHER.GET);
    yield fork(getUserGenTeacher, action.params);
  }
}

export function* postUserCreate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.USER_CREATE, { ...params });
    yield put({
      type: Actions.USER_CREATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_CREATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserCreate() {
  while (true) {
    const action = yield take(Actions.USER_CREATE.POST);
    yield fork(postUserCreate, action.params);
  }
}

export function* postUserDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.USER_DELETE, { ...params });
    yield put({
      type: Actions.USER_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserDelete() {
  while (true) {
    const action = yield take(Actions.USER_DELETE.POST);
    yield fork(postUserDelete, action.params);
  }
}

export function* postUserMainTeacherBatchBind(params: { [key: string]: any }) {
  try {
    const response = yield call(
      NetWork.post,
      API.USER_MAIN_TEACHER_BATCH_BIND,
      { ...params }
    );
    yield put({
      type: Actions.USER_MAIN_TEACHER_BATCH_BIND.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_MAIN_TEACHER_BATCH_BIND.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserMainTeacherBatchBind() {
  while (true) {
    const action = yield take(Actions.USER_MAIN_TEACHER_BATCH_BIND.POST);
    yield fork(postUserMainTeacherBatchBind, action.params);
  }
}

export function* getUserHomeworkList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_HOMEWORK_LIST, {
      params: params
    });
    yield put({
      type: Actions.USER_HOMEWORK_LIST.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_HOMEWORK_LIST.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserHomeworkList() {
  while (true) {
    const action = yield take(Actions.USER_HOMEWORK_LIST.GET);
    yield fork(getUserHomeworkList, action.params);
  }
}

export function* getUserHomeworkSteps(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_HOMEWORK_STEPS, {
      params: params
    });
    yield put({
      type: Actions.USER_HOMEWORK_STEPS.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_HOMEWORK_STEPS.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserHomeworkSteps() {
  while (true) {
    const action = yield take(Actions.USER_HOMEWORK_STEPS.GET);
    yield fork(getUserHomeworkSteps, action.params);
  }
}

export function* getUserHomeworkSubjects(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_HOMEWORK_SUBJECTS, {
      params: params
    });
    yield put({
      type: Actions.USER_HOMEWORK_SUBJECTS.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_HOMEWORK_SUBJECTS.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserHomeworkSubtects() {
  while (true) {
    const action = yield take(Actions.USER_HOMEWORK_SUBJECTS.GET);
    yield fork(getUserHomeworkSubjects, action.params);
  }
}

export function* postHomeWorkReViewDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.HOMEWORK_REVIEW_DELETE, {
      ...params
    });
    yield put({
      type: Actions.HOMEWORK_REVIEW_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.HOMEWORK_REVIEW_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchHomeWorkReViewDelete() {
  while (true) {
    const action = yield take(Actions.HOMEWORK_REVIEW_DELETE.POST);
    yield fork(postHomeWorkReViewDelete, action.params);
  }
}

export function* postHomeWorkReViewSubmit(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.HOMEWORK_REVIEW_SUBMIT, {
      ...params
    });
    yield put({
      type: Actions.HOMEWORK_REVIEW_SUBMIT.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.HOMEWORK_REVIEW_SUBMIT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchHomeWorkReViewSubmit() {
  while (true) {
    const action = yield take(Actions.HOMEWORK_REVIEW_SUBMIT.POST);
    yield fork(postHomeWorkReViewSubmit, action.params);
  }
}

export function* getHomeWorkReViewList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.HOMEWORK_REVIEW_LIST, {
      params: params
    });
    yield put({
      type: Actions.HOMEWORK_REVIEW_LIST.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.HOMEWORK_REVIEW_LIST.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchHomeWorkReViewList() {
  while (true) {
    const action = yield take(Actions.HOMEWORK_REVIEW_LIST.GET);
    yield fork(getHomeWorkReViewList, action.params);
  }
}




export function* getUserTask(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_TASK, {
      params: params
    });
    yield put({
      type: Actions.USER_TASK.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_TASK.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserTask() {
  while (true) {
    const action = yield take(Actions.USER_TASK.GET);
    yield fork(getUserTask, action.params);
  }
}


//修改用户组件
export function* postUserUpdateUserComponent(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.USER_UPDATE_USER_COMPONENT, {
      ...params
    });
    yield put({
      type: Actions.USER_UPDATE_USER_COMPONENT.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_UPDATE_USER_COMPONENT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserUpdateUserComponent() {
  while (true) {
    const action = yield take(Actions.USER_UPDATE_USER_COMPONENT.POST);
    yield fork(postUserUpdateUserComponent, action.params);
  }
}


export function* getUserComponent(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get,API.USER_USER_COMPONENT, {
      params: params
    });
    yield put({
      type: Actions.USER_USER_COMPONENT.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.USER_USER_COMPONENT.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchUserComponent() {
  while (true) {
    const action = yield take(Actions.USER_USER_COMPONENT.GET);
    yield fork(getUserComponent, action.params);
  }
}


export function* getComponentList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get,API.COMPONENT_LIST, {
      params: params
    });
    yield put({
      type: Actions.COMPONENT_LIST.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.COMPONENT_LIST.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchComponentList() {
  while (true) {
    const action = yield take(Actions.COMPONENT_LIST.GET);
    yield fork(getComponentList, action.params);
  }
}


//用户课程信息
export function* getUserScheduleList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_SCHEDULE_LIST, { params: params });
    yield put({
      type: Actions.USER_SCHEDULE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.USER_SCHEDULE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchUserScheduleList() {
  while (true) {
    const action = yield take(Actions.USER_SCHEDULE_LIST.GET);
    yield fork(getUserScheduleList, action.params);
  }
}

//用户回课情况
export function* getuserHomeworkAnswer(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.USER_HOMEWORK_ANSWER, { params: params });
    yield put({
      type: Actions.USER_HOMEWORK_ANSWER.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.USER_HOMEWORK_ANSWER.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchuserHomeworkAnswer() {
  while (true) {
    const action = yield take(Actions.USER_HOMEWORK_ANSWER.GET);
    yield fork(getuserHomeworkAnswer, action.params);
  }
}