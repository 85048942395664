import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";

//ai课程表
export function* getAiList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_LIST, { params: params });
    yield put({
      type: Actions.AI_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiList() {
  while (true) {
    const action = yield take(Actions.AI_LIST.GET);
    yield fork(getAiList, action.params);
  }
}

//ai课点评视频列表
export function* getAiEvaluationList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_EVALUATION_LIST, {
      params: params
    });
    yield put({
      type: Actions.AI_EVALUATION_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_EVALUATION_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiEvaluationList() {
  while (true) {
    const action = yield take(Actions.AI_EVALUATION_LIST.GET);
    yield fork(getAiEvaluationList, action.params);
  }
}

// ai课 错误项列表
export function* getAiWrongList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_WRONG_LIST, {
      params: params
    });
    yield put({
      type: Actions.AI_WRONG_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_WRONG_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiWrongList() {
  while (true) {
    const action = yield take(Actions.AI_WRONG_LIST.GET);
    yield fork(getAiWrongList, action.params);
  }
}

// ai课 设置曲谱
export function* getAiCourseInit(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_COURSE_INIT, {
      params: params
    });
    yield put({
      type: Actions.AI_COURSE_INIT.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_INIT.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiCourseInit() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_INIT.GET);
    yield fork(getAiCourseInit, action.params);
  }
}

//ai获取题目提示
export function* getAiCourseTips(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_COURSE_TIPS, {
      params: params
    });
    yield put({
      type: Actions.AI_COURSE_TIPS.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_TIPS.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiCourseTips() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_TIPS.GET);
    yield fork(getAiCourseTips, action.params);
  }
}

//ai添加错误项
export function* postAiWrongAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_WRONG_ADD, {
      ...params
    });
    yield put({
      type: Actions.AI_WRONG_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_WRONG_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiWrongAdd() {
  while (true) {
    const action = yield take(Actions.AI_WRONG_ADD.POST);
    yield fork(postAiWrongAdd, action.params);
  }
}

//添加ai课点评视频
export function* postAiEvaluationDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_EVALUATION_DETAIL, {
      ...params
    });
    yield put({
      type: Actions.AI_EVALUATION_DETAIL.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_EVALUATION_DETAIL.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiEvaluationDetail() {
  while (true) {
    const action = yield take(Actions.AI_EVALUATION_DETAIL.POST);
    yield fork(postAiEvaluationDetail, action.params);
  }
}

//查询AI课程
export function* getAiDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_DETAIL, { params: params });
    yield put({
      type: Actions.AI_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiDetail() {
  while (true) {
    const action = yield take(Actions.AI_DETAIL.GET);
    yield fork(getAiDetail, action.params);
  }
}

//ai课程 跳过本题
export function* postAiCourseSkip(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_SKIP, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_SKIP.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_SKIP.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseSkip() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_SKIP.POST);
    yield fork(postAiCourseSkip, action.params);
  }
}

//ai课程 开始上课
export function* postAiCourseStart(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_START, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_START.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_START.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseStart() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_START.POST);
    yield fork(postAiCourseStart, action.params);
  }
}

//ai课程 打分
export function* postAiCourseMark(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_MARK, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_MARK.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_MARK.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseMark() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_MARK.POST);
    yield fork(postAiCourseMark, action.params);
  }
}

//ai课程 提交错误选项
export function* postAiCourseWrongItems(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_WRONG_ITEMS, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_WRONG_ITEMS.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_WRONG_ITEMS.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseWrongItems() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_WRONG_ITEMS.POST);
    yield fork(postAiCourseWrongItems, action.params);
  }
}

//ai课程 是否继续
export function* postAiCourseContinue(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_CONTINUE, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_CONTINUE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_CONTINUE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseContinue() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_CONTINUE.POST);
    yield fork(postAiCourseContinue, action.params);
  }
}

//ai课程 结束上课
export function* postAiCourseFinish(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_FINISH, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_FINISH.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_FINISH.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseFinish() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_FINISH.POST);
    yield fork(postAiCourseFinish, action.params);
  }
}

//修改ai课点评视频
export function* postEvaluationUpate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_EVALUATION_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AI_EVALUATION_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_EVALUATION_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiEvaluationUpdate() {
  while (true) {
    const action = yield take(Actions.AI_EVALUATION_UPDATE.POST);
    yield fork(postEvaluationUpate, action.params);
  }
}

//修改ai课
export function* postAiUpate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AI_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiUpate() {
  while (true) {
    const action = yield take(Actions.AI_UPDATE.POST);
    yield fork(postAiUpate, action.params);
  }
}

//修改ai课程错误项
export function* postAiWrongUpate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_WRONG_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AI_WRONG_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_WRONG_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiWrongUpate() {
  while (true) {
    const action = yield take(Actions.AI_WRONG_UPDATE.POST);
    yield fork(postAiWrongUpate, action.params);
  }
}

//删除ai课程点评视频
export function* postAiEvaluationDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_EVALUATION_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AI_EVALUATION_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_EVALUATION_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiEvaluationDelete() {
  while (true) {
    const action = yield take(Actions.AI_EVALUATION_DELETE.POST);
    yield fork(postAiEvaluationDelete, action.params);
  }
}

//删除ai课程错误项
export function* postAiWrongDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_WRONG_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AI_WRONG_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_WRONG_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiWrongDelete() {
  while (true) {
    const action = yield take(Actions.AI_WRONG_DELETE.POST);
    yield fork(postAiWrongDelete, action.params);
  }
}

//删除ai课程错误项
export function* postAiCourseSubjectChoices(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_COURSE_SUBJECT_CHOICES, {
      ...params
    });
    yield put({
      type: Actions.AI_COURSE_SUBJECT_CHOICES.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_COURSE_SUBJECT_CHOICES.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiCourseSubjectChoices() {
  while (true) {
    const action = yield take(Actions.AI_COURSE_SUBJECT_CHOICES.POST);
    yield fork(postAiCourseSubjectChoices, action.params);
  }
}

//添加AI课程
export function* postAiAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_ADD, {
      ...params
    });
    yield put({
      type: Actions.AI_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiAdd() {
  while (true) {
    const action = yield take(Actions.AI_ADD.POST);
    yield fork(postAiAdd, action.params);
  }
}

//添加AI课点评视频
export function* postAiEvaluationAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_EVALUATION_ADD, {
      ...params
    });
    yield put({
      type: Actions.AI_EVALUATION_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_EVALUATION_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiEvaluationAdd() {
  while (true) {
    const action = yield take(Actions.AI_EVALUATION_ADD.POST);
    yield fork(postAiEvaluationAdd, action.params);
  }
}

//查看AI课程错误项
export function* getAiWrongDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_WRONG_DETAIL, {
      params: params
    });
    yield put({
      type: Actions.AI_WRONG_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_WRONG_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiWrongDetail() {
  while (true) {
    const action = yield take(Actions.AI_WRONG_DETAIL.GET);
    yield fork(getAiWrongDetail, action.params);
  }
}

//删除AI课程
export function* postAiDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AI_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiDelete() {
  while (true) {
    const action = yield take(Actions.AI_DELETE.POST);
    yield fork(postAiDelete, action.params);
  }
}

//ai/segment/list ai题图谱列表
export function* getAiSegmentList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_SEGMENT_LIST, {
      params: params
    });
    yield put({
      type: Actions.AI_SEGMENT_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_SEGMENT_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiSegmentList() {
  while (true) {
    const action = yield take(Actions.AI_SEGMENT_LIST.GET);
    yield fork(getAiSegmentList, action.params);
  }
}

//ai/segment/add
export function* postAiSegmentAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_SEGMENT_ADD, {
      ...params
    });
    yield put({
      type: Actions.AI_SEGMENT_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_SEGMENT_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiSegmentAdd() {
  while (true) {
    const action = yield take(Actions.AI_SEGMENT_ADD.POST);
    yield fork(postAiSegmentAdd, action.params);
  }
}

export function* postAiChoiceUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_CHOICE_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AI_CHOICE_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_CHOICE_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiChoiceUpdate() {
  while (true) {
    const action = yield take(Actions.AI_CHOICE_UPDATE.POST);
    yield fork(postAiChoiceUpdate, action.params);
  }
}

export function* postAiChoiceDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_CHOICE_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AI_CHOICE_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_CHOICE_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiChoiceDelete() {
  while (true) {
    const action = yield take(Actions.AI_CHOICE_DELETE.POST);
    yield fork(postAiChoiceDelete, action.params);
  }
}

export function* postAiChoiceAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_CHOICE_ADD, {
      ...params
    });
    yield put({
      type: Actions.AI_CHOICE_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_CHOICE_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiChoiceAdd() {
  while (true) {
    const action = yield take(Actions.AI_CHOICE_ADD.POST);
    yield fork(postAiChoiceAdd, action.params);
  }
}

export function* postAiSegmentUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_SEGMENT_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AI_SEGMENT_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_SEGMENT_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiSegmentUpdate() {
  while (true) {
    const action = yield take(Actions.AI_SEGMENT_UPDATE.POST);
    yield fork(postAiSegmentUpdate, action.params);
  }
}

export function* postAiSegmentDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_SEGMENT_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AI_SEGMENT_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_SEGMENT_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiSegmentDelete() {
  while (true) {
    const action = yield take(Actions.AI_SEGMENT_DELETE.POST);
    yield fork(postAiSegmentDelete, action.params);
  }
}

export function* postAiOptionUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_OPTION_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.AI_OPTION_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_OPTION_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiOptionUpdate() {
  while (true) {
    const action = yield take(Actions.AI_OPTION_UPDATE.POST);
    yield fork(postAiOptionUpdate, action.params);
  }
}

export function* getAiChoiceList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_CHOICE_LIST, {
      params: params
    });
    yield put({
      type: Actions.AI_CHOICE_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_CHOICE_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiChoiceList() {
  while (true) {
    const action = yield take(Actions.AI_CHOICE_LIST.GET);
    yield fork(getAiChoiceList, action.params);
  }
}

export function* postAiOptionDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_OPTION_DELETE, {
      ...params
    });
    yield put({
      type: Actions.AI_OPTION_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_OPTION_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiOptionDelete() {
  while (true) {
    const action = yield take(Actions.AI_OPTION_DELETE.POST);
    yield fork(postAiOptionDelete, action.params);
  }
}

export function* postAiOptionAdd(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.AI_OPTION_ADD, {
      ...params
    });
    yield put({
      type: Actions.AI_OPTION_ADD.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AI_OPTION_ADD.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchAiOptionAdd() {
  while (true) {
    const action = yield take(Actions.AI_OPTION_ADD.POST);
    yield fork(postAiOptionAdd, action.params);
  }
}

export function* getAiChoiceDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_AI_LIST, {
      params: params
    });
    yield put({
      type: Actions.AI_CHOICE_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_CHOICE_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiChoiceDetail() {
  while (true) {
    const action = yield take(Actions.AI_CHOICE_DETAIL.GET);
    yield fork(getAiChoiceDetail, action.params);
  }
}

export function* getAiAiList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.AI_AI_LIST, {
      params: params
    });
    yield put({
      type: Actions.AI_AI_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.AI_AI_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAiAiList() {
  while (true) {
    const action = yield take(Actions.AI_AI_LIST.GET);
    yield fork(getAiAiList, action.params);
  }
}

export function* getAbilityList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.ABILITY_LIST, {
      params: params
    });
    yield put({
      type: Actions.ABILITY_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.ABILITY_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchAbilityList() {
  while (true) {
    const action = yield take(Actions.ABILITY_LIST.GET);
    yield fork(getAbilityList, action.params);
  }
}
