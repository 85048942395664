import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import Axios from "axios";
import Path from "../path";
import { plainToClass } from "class-transformer";

export function* getServerTime() {
  try {
    const response = yield call(NetWork.get, API.SERVER_TIME, {});
    yield put({
      type: Actions.SERVER_TIME.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.SERVER_TIME.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchServerTime() {
  while (true) {
    yield take(Actions.SERVER_TIME.GET);
    yield fork(getServerTime);
  }
}

export function* getAreaData() {
  try {
    const response = yield call(Axios.get, Path.AREA_JSON, {});
    yield put({
      type: Actions.AREA_JSON.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.AREA_JSON.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchGetAreaData() {
  while (true) {
    yield take(Actions.AREA_JSON.GET);
    yield fork(getAreaData);
  }
}

export function* getPubMetronome(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.PUB_METRONOME, { params: params });
    yield put({
      type: Actions.PUB_METRONOME.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.PUB_METRONOME.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchPubMetronome() {
  while (true) {
    const action = yield take(Actions.PUB_METRONOME.GET);
    yield fork(getPubMetronome, action.params);
  }
}