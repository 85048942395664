import { fork, take, call, put } from "redux-saga/effects";
import NetWork from "..";
import API from "../api";
import Actions from "../actions";
import Response from "../../models/response";
import ErrorBuilder from "../error_builder";
import { plainToClass } from "class-transformer";



export function* getVideoList(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.VIDEO_LIST, { params: params });
    yield put({
      type: Actions.VIDEO_LIST.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.VIDEO_LIST.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchVideoList() {
  while (true) {
    const action = yield take(Actions.VIDEO_LIST.GET);
    yield fork(getVideoList, action.params);
  }
}


export function* getVideoDetail(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.get, API.VIDEO_DETAIL, { params: params });
    yield put({
      type: Actions.VIDEO_DETAIL.RAW,
      response: plainToClass(Response, response.data),
      params: params
    });
  } catch (e) {
    yield put({
      type: Actions.VIDEO_DETAIL.ERROR,
      response: ErrorBuilder.local(e),
      params: params
    });
  }
}

export function* watchVideoDetail() {
  while (true) {
    const action = yield take(Actions.VIDEO_DETAIL.GET);
    yield fork(getVideoDetail, action.params);
  }
}





export function* postVideoUpdate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.VIDEO_UPDATE, {
      ...params
    });
    yield put({
      type: Actions.VIDEO_UPDATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.VIDEO_UPDATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchVideoUpdate() {
  while (true) {
    const action = yield take(Actions.VIDEO_UPDATE.POST);
    yield fork(postVideoUpdate, action.params);
  }
}

export function* postVideoDelete(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.VIDEO_DELETE, {
      ...params
    });
    yield put({
      type: Actions.VIDEO_DELETE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.VIDEO_DELETE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchVideoDelete() {
  while (true) {
    const action = yield take(Actions.VIDEO_DELETE.POST);
    yield fork(postVideoDelete, action.params);
  }
}

export function* postVideocCreate(params: { [key: string]: any }) {
  try {
    const response = yield call(NetWork.post, API.VIDEO_CREATE, {
      ...params
    });
    yield put({
      type: Actions.VIDEO_CREATE.RAW,
      response: plainToClass(Response, response.data)
    });
  } catch (e) {
    yield put({
      type: Actions.VIDEO_CREATE.ERROR,
      response: ErrorBuilder.local(e)
    });
  }
}

export function* watchVideocCreate() {
  while (true) {
    const action = yield take(Actions.VIDEO_CREATE.POST);
    yield fork(postVideocCreate, action.params);
  }
}
